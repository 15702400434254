import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import { transformPaymentTermsFromBackendToFrontend } from '../../Global/Helpers/paymentTermsHelpers';
import { JsonNumsToStrings } from '../../Global/Services/JsonProcessingService';
import { IAddresses } from '../../Models/Address.model';
import {
    IEntityIdentification,
    IPostBuyerAward,
    IPurchaseOrderItem,
} from '../../Models/AwardsAndPO.model';
import {
    IGetAllEventSeller,
    IGetBuyerRFQBid,
    IGetBuyerRFQBidItemDetails,
    IGetBuyerRFQBidWithoutBidItems,
    IGetRFQDetails,
    IGetRFQItemDetails,
    IPageDetails,
    IPostBuyerRFQBid,
    IRFQDashboardInfo,
    IRFQItemAddedVendors,
    IRFQList,
    IRFQVendorWhoOfferedBack,
    IStatusMap,
} from '../../Models/RFQ.model';
import {
    IAllUserEventPermissions,
    IUserDetails,
} from '../../Models/UserDetails.model';

import { convertAdditionalCostBackendToFrontend } from '../../AdditionalCost/helpers';
import { fetchAllBidDetailsForMultipleVendorApiSlice } from '../../Components/Buyer/Events/QuoteComparison/getBidsDetailsHooks';
import { calculateNewItemTotalWithDecimalPlaces } from '../../Components/Shared/totalCalculator';
import { IBackendSingleLatestBidResponse } from '../../Events/RFQAnalytics/Services/MainDataViewsService';
import {
    ISectionField,
    RfqStandardSections,
} from '../../Global/Interfaces/TemplateInterface';
import { IVendorAddresses } from '../../Organizations/Admin/Hooks/Vendors/VendorDetailsHook';
import {
    DashBoardFilter,
    TEventsUserList,
} from '../../ProjectGlCostCenter/interface/project.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';
import { store } from '../../Redux/Store';
import { del, get, post, put } from '../../Utils/api';
import * as types from './eventTypes';
// import { convertAdditionalCostBackendToFrontend } from '../../AdditionalCost/helpers';

const statuseMap: IStatusMap = {
    all: 'all',
    drafts: 'drafts',
    ongoing: 'ongoing',
    finished: 'finished',
};

export interface IFetchDashboardDataPayload {
    dashboard_view: string;
    tab: string;
    search_text: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    items_per_page: number;
    page_number: number;
    filters: DashBoardFilter | null;
}

export interface IResponseItemVendors {
    seller_entity: {
        entity_id: string;
        entity_name: string;
        entity_logo: string[];
        enterprise_name: string;
        contacts: {
            emails: string[];
            websites: string[];
            phone_numbers: string[];
        };
        is_virtual: boolean;
        verification_status: VerificationStatus;
    };
    seller_contacts: {
        vendor_contact_id: string;
        full_name: string;
        primary_email: string;
        emails: {
            email: string;
            type: string;
        }[];
        phone_numbers: string[];
        tags: string[];
    }[];
}

export interface IVendorContactDetails {
    rfq_event_id: string;
    rfq_event_item_id: string;
    seller_entity: {
        entity_id: string;
        entity_name: string;
        entity_logo: any[];
        enterprise_name: string;
        contacts: {
            emails: any[];
            websites: any[];
            phone_numbers: any[];
        }[];
        is_virtual: boolean;
        verification_status: string;
    };
    seller_contacts:
        | {
              vendor_contact_id: string;
              buyer_entity: string;
              seller_entity: string;
              full_name: string;
              primary_email: string;
              emails?: {
                  email: string;
                  type: string;
              }[];
              phone_numbers: string[];
              tags: any[];
              is_primary: boolean;
              user: string;
              status: string;
          }[]
        | null;
}

interface IVendorContact {
    emails: { email: string; type: 'CC' | 'SECONDARY' }[];
    phone_numbers: string[];
    full_name: string;
    is_primary: boolean;
    user: {
        user_id: string;
    };
    primary_email?: string;
}
export interface IEventOnboardingModulesAndKeys {
    Event: {
        module: 'EVENT';
        fields:
            | 'EVENT_DEFAULTS'
            | 'EVENT_ADDRESS'
            | 'EVENT_ITEMS'
            | 'EVENT_VENDORS'
            | 'EVENT_REVIEW'
            | 'EVENT_SUBMIT';
    };
    Negotiate: {
        module: 'NEGOTIATE';
        fields: 'BUYER_NEGOTIATE' | 'BUYER_REVIEW_BID' | 'EVENT_END';
    };
    Award: {
        module: 'AWARD';
        fields: 'PO_ITEMS' | 'PO_CREATE' | 'PO_SUBMIT';
    };
}

export interface IEventOnboarding {
    module: string;
    fields: {
        key: string;
        selected: boolean;
    }[];
}

export interface IEventOnboardingChecklist {
    checklist: IEventOnboarding[];
}

export const getTabCount = (): Promise<{
    drafts: number;
    ongoing: number;
    finished: number;
    all: number;
}> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<
                {
                    dashboard_view: string;
                },
                {
                    tab_counts: {
                        drafts: number;
                        ongoing: number;
                        all: number;
                        finished: number;
                    };
                }
            >('/dashboard/tab_counts/', {
                dashboard_view: 'rfq_event_buyer',
            });
            resolve(resp.data.tab_counts);
        } catch (err) {
            reject();
        }
    });
};

export const getTabCountOrders = (): Promise<{
    drafts: number;
    ongoing: number;
    finished: number;
    all: number;
}> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<
                {
                    dashboard_view: string;
                },
                {
                    tab_counts: {
                        drafts: number;
                        ongoing: number;
                        all: number;
                        finished: number;
                    };
                }
            >('/dashboard/tab_counts/', {
                dashboard_view: 'inbound_order',
            });
            resolve(resp.data.tab_counts);
        } catch (err) {
            reject();
        }
    });
};
export const getTabCountReq = (): Promise<{
    drafts: number;
    ongoing: number;
    finished: number;
    all: number;
}> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<
                {
                    dashboard_view: string;
                },
                {
                    tab_counts: {
                        drafts: number;
                        ongoing: number;
                        all: number;
                        finished: number;
                    };
                }
            >('/dashboard/tab_counts/', {
                dashboard_view: 'requisition',
            });
            resolve(resp.data.tab_counts);
        } catch (err) {
            reject();
        }
    });
};

export const {
    useGetEventsDashboardDataMutation,
    useGetEventsListOfAllUserListQuery,
} = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getEventsDashboardData: build.mutation<any, IFetchDashboardDataPayload>(
            {
                query: ({
                    dashboard_view,
                    items_per_page,
                    page_number,
                    search_text,
                    sort_fields,
                    tab,
                    filters,
                }) => {
                    const tabForPayload: string = (statuseMap as any)[tab];

                    return {
                        url: '/dashboard/',
                        method: 'POST',
                        body: {
                            dashboard_view: 'rfq_event_buyer',
                            tab: tabForPayload,
                            search_text:
                                search_text?.length && search_text?.length > 0
                                    ? search_text
                                    : '',
                            sort_fields: sort_fields ?? [],
                            filters,
                            items_per_page: items_per_page,
                            page_number: page_number,
                        } as IFetchDashboardDataPayload,
                    };
                },
            }
        ),

        getEventsListOfAllUserList: build.query<TEventsUserList, {}>({
            query: () => ({
                url: '/events/rfq/columns/?enterprise_type=BUYER',
            }),
        }),
    }),
});

export const fetchEventDashboardData = async ({
    tab,
    search_text,
    sort_fields,
    items_per_page,
    page_number,
    filters,
}: {
    tab: keyof IStatusMap;
    search_text: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    items_per_page: number;
    page_number: number;
    filters: DashBoardFilter | null;
}): Promise<{ eventList: IRFQDashboardInfo[]; pageDetails: IPageDetails }> => {
    const postData: IFetchDashboardDataPayload = {
        dashboard_view: 'rfq_event_buyer',
        tab: statuseMap[tab],
        search_text:
            search_text?.length && search_text?.length > 0 ? search_text : '',
        sort_fields: sort_fields ?? [],
        items_per_page: items_per_page,
        page_number: page_number,
        filters: filters,
    };

    const resp = await post<IFetchDashboardDataPayload, any>(
        `/dashboard/`,
        postData
    );
    return {
        eventList: resp.data.data,
        pageDetails: resp.data.metadata,
    };
};

export const fetchRFQList = (type: string | null): Promise<IRFQList[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp: any;
            if (type === 'all')
                resp = await get<IRFQList[]>('/events/dashboard/buyer/');
            else if (type === null || type === 'drafts')
                resp = await get<IRFQList[]>(
                    '/events/dashboard/buyer/?event_status=[DRAFT, APPROVAL_PENDING, REWORK]'
                );
            else if (type === 'ongoing')
                resp = await get<IRFQList[]>(
                    '/events/dashboard/buyer/?event_status=[ONGOING, PAUSED, AWARD_STAGE, PURCHASE_ORDER_ISSUED]'
                );
            else if (type === 'finished')
                resp = await get<IRFQList[]>(
                    '/events/dashboard/buyer/?event_status=[COMPLETED, CLOSED, REJECTED,PURCHASE_ORDER_ACCEPTED]'
                );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchExistingRFQsContainingItem = (
    buyer_item_id: string
): Promise<IRFQList[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IRFQList[]>(
                `/events/rfq/?enterprise_type=BUYER&rfq_event_status=[DRAFT, APPROVAL_PENDING, REWORK, ONGOING, PAUSED, AWARD_STAGE]&buyer_item_ids[]=${buyer_item_id}`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const { useLazyFetchExistingRFQsContainingItemQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            fetchExistingRFQsContainingItem: build.query<
                IRFQList[],
                {
                    buyer_item_id: string;
                }
            >({
                query: ({ buyer_item_id }) =>
                    `/events/rfq/?enterprise_type=BUYER&rfq_event_status=[DRAFT, APPROVAL_PENDING, REWORK, ONGOING, PAUSED, AWARD_STAGE]&buyer_item_ids[]=${buyer_item_id}`,
            }),
        }),
    });

export const fetchRFQDetails = (
    rfq_event_id: string,
    enterprise_type: 'BUYER' | 'SELLER',
    event_type?: 'DIRECT' | 'FROM_EVENT'
): Promise<IGetRFQDetails> => {
    return new Promise(async (resolve, reject) => {
        if (event_type === 'DIRECT') {
            try {
                let rfq_resp = await get<any>(
                    `/events/po_group/${rfq_event_id}/?enterprise_type=${enterprise_type}`
                );

                //FIXME: THIS CONDITION IS TEMPORARY AND SHOULD BE FIXED ONCE BE IS FIXED
                if (rfq_resp.data.additional_details.child_rfq_id === 'None') {
                    rfq_resp.data.additional_details.child_rfq_id = null;
                }
                let resp = {
                    ...rfq_resp.data,
                    rfq_entry_id: rfq_resp.data.po_group_entry_id,
                    rfq_event_creator: rfq_resp.data.po_group_event_creator,
                    rfq_default_information: rfq_resp.data.default_information,
                    event_additional_details: {
                        access: 'ACCESS_RESTRICTED',
                    },
                };
                resolve(resp);
            } catch (err) {
                reject();
            }
        } else {
            try {
                let rfq_resp = await get<IGetRFQDetails>(
                    `/events/rfq/${rfq_event_id}/?enterprise_type=${enterprise_type}`
                );
                //FIXME: THIS CONDITION IS TEMPORARY AND SHOULD BE FIXED ONCE BE IS FIXED

                if (rfq_resp.data.additional_details.child_rfq_id === 'None') {
                    rfq_resp.data.additional_details.child_rfq_id = null;
                }

                rfq_resp.data.rfq_default_information.defaultPaymentTerms =
                    transformPaymentTermsFromBackendToFrontend({
                        deliverables_payment_terms:
                            rfq_resp.data.rfq_default_information
                                .default_event_deliverables_payment_terms,
                        payment_terms:
                            rfq_resp.data.rfq_default_information
                                .default_event_payment_terms,
                        prepayment_percentage:
                            rfq_resp.data.rfq_default_information
                                .default_event_prepayment_percentage,
                    });

                resolve(rfq_resp.data);
            } catch (err) {
                reject();
            }
        }
    });
};

export const fetchRFQItemDetails = (
    rfq_event_id: string,
    event_type?: 'DIRECT' | 'FROM_EVENT'
): Promise<IGetRFQItemDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            if (event_type === 'DIRECT') {
                let item_resp = await get<any>(
                    `/events/po_group/${rfq_event_id}/items/?enterprise_type=BUYER`
                );
                let allItemsData: any[] = item_resp.data;
                allItemsData = allItemsData.map((item) => ({
                    ...item,
                    rfq_item_entry_id: item.po_group_item_entry_id,
                }));
                for (let rfqItem of allItemsData) {
                    if (
                        rfqItem.quantity_information.max_awardable_quantity ===
                            null ||
                        rfqItem.quantity_information.max_awardable_quantity ===
                            undefined ||
                        isNaN(
                            parseFloat(
                                rfqItem.quantity_information
                                    .max_awardable_quantity
                            )
                        )
                    ) {
                        rfqItem.quantity_information.max_awardable_quantity =
                            rfqItem.quantity_information.quantity;
                    }
                    rfqItem.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage:
                                rfqItem.prepayment_percentage,
                            payment_terms: rfqItem.payment_terms,
                            deliverables_payment_terms:
                                rfqItem.deliverables_payment_terms,
                        });
                }
                JsonNumsToStrings(allItemsData);
                resolve(allItemsData);
            } else {
                let item_resp = await get<any>(
                    `/events/rfq/${rfq_event_id}/items/?enterprise_type=BUYER`
                );
                let allItemsData: IGetRFQItemDetails[] = item_resp.data;
                for (let rfqItem of allItemsData) {
                    if (
                        rfqItem.quantity_information.max_awardable_quantity ===
                            null ||
                        rfqItem.quantity_information.max_awardable_quantity ===
                            undefined ||
                        isNaN(
                            parseFloat(
                                rfqItem.quantity_information
                                    .max_awardable_quantity
                            )
                        )
                    ) {
                        rfqItem.quantity_information.max_awardable_quantity =
                            rfqItem.quantity_information.quantity;
                    }
                    rfqItem.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: (rfqItem as any)
                                .prepayment_percentage,
                            payment_terms: (rfqItem as any).payment_terms,
                            deliverables_payment_terms: (rfqItem as any)
                                .deliverables_payment_terms,
                        });
                }
                JsonNumsToStrings(allItemsData);
                resolve(allItemsData);
            }
        } catch (err) {
            reject();
        }
    });
};

export const { useFetchRFQItemDetailsQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchRFQItemDetails: build.query<
            {
                data: IGetRFQItemDetails[];
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            | {
                  body: {
                      dashboard_view: 'rfq_event_item_buyer';
                      tab: 'all';
                      sort_fields: any[];
                      search_text: string;
                      items_per_page: number;
                      page_number: number;
                      query_data: {
                          rfq_event_id: string;
                          rfq_items_list: string[] | undefined;
                          excluded: boolean | undefined;
                          seller_list: string[] | undefined;
                          seller_search?: string;
                          enterprise_bom_id?: string;
                          sub_bom_item_id?: string;
                          requisition_id?: string | null;
                      };
                  };
                  otherInfoRequired: {
                      type: 'FROM_EVENT';
                      // searchQueryType: 'ITEMS' | 'VENDORS';
                      // poCount: number;
                  };
              }
            | {
                  body: {
                      dashboard_view: 'po_group_item';
                      tab: 'all';
                      sort_fields: any[];
                      search_text: string;
                      items_per_page: number;
                      page_number: number;
                      query_data: {
                          po_group_id: string;
                          rfq_items_list: string[] | undefined;
                          excluded: boolean | undefined;
                          seller_list: string[] | undefined;
                          seller_search?: string;
                          enterprise_bom_id?: string;
                          sub_bom_item_id?: string;
                      };
                  };
                  otherInfoRequired: {
                      type: 'DIRECT';
                      // searchQueryType: 'ITEMS' | 'VENDORS';
                      // poCount: number;
                  };
              }
        >({
            queryFn: async ({ body, otherInfoRequired }) => {
                // if (
                //     store.getState().EventCreationStore.searchQueryType ===
                //     'VENDORS'
                // ) {
                //     body.query_data.seller_search = body.search_text;
                //     body.search_text = '';
                // }

                try {
                    const data = await post<
                        any,
                        {
                            data: IGetRFQItemDetails[];
                            metadata: {
                                current_page: number;
                                has_next: boolean;
                                has_previous: boolean;
                                total_pages: number;
                                page_range: {
                                    start: number;
                                    stop: number;
                                };
                                count: number;
                            };
                            counts: {
                                all: number;
                            };
                        }
                    >('/dashboard/', body);

                    let response: any = data.data;
                    if (otherInfoRequired.type === 'DIRECT') {
                        response.data = response.data.map((item: any) => ({
                            ...item,
                            rfq_item_entry_id: item.po_group_item_entry_id,
                        }));

                        response.data.forEach((rfqItem: any) => {
                            if (
                                rfqItem.quantity_information
                                    .max_awardable_quantity === null ||
                                rfqItem.quantity_information
                                    .max_awardable_quantity === undefined ||
                                isNaN(
                                    parseFloat(
                                        rfqItem.quantity_information
                                            .max_awardable_quantity
                                    )
                                )
                            ) {
                                rfqItem.quantity_information.max_awardable_quantity =
                                    rfqItem.quantity_information.quantity;
                            }
                            rfqItem.paymentTerms =
                                transformPaymentTermsFromBackendToFrontend({
                                    prepayment_percentage:
                                        rfqItem.prepayment_percentage,
                                    payment_terms: rfqItem.payment_terms,
                                    deliverables_payment_terms:
                                        rfqItem.deliverables_payment_terms,
                                });

                            rfqItem.delivery_information =
                                rfqItem.delivery_schedule;
                        });

                        return { data: response };
                    } else {
                        response.data.forEach((rfqItem: any) => {
                            if (
                                rfqItem.quantity_information
                                    .max_awardable_quantity === null ||
                                rfqItem.quantity_information
                                    .max_awardable_quantity === undefined ||
                                isNaN(
                                    parseFloat(
                                        rfqItem.quantity_information
                                            .max_awardable_quantity
                                    )
                                )
                            ) {
                                rfqItem.quantity_information.max_awardable_quantity =
                                    rfqItem.quantity_information.quantity;
                            }
                            rfqItem.paymentTerms =
                                transformPaymentTermsFromBackendToFrontend({
                                    prepayment_percentage:
                                        rfqItem.prepayment_percentage,
                                    payment_terms: rfqItem.payment_terms,
                                    deliverables_payment_terms:
                                        rfqItem.deliverables_payment_terms,
                                });
                        });

                        return { data: response };
                    }
                } catch (error: any) {
                    return { error };
                }
            },
        }),
    }),
});

export const fetchSpecificRFQItemDetails = (
    rfq_id: string,
    rfq_item_id: string
): Promise<IGetRFQItemDetails> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<any>(
                `/events/rfq/${rfq_id}/items/${rfq_item_id}/?enterprise_type=BUYER`
            );
            let processed_data = resp.data;
            processed_data.procurement_information =
                processedProcurementInfoForReceiving(
                    processed_data.procurement_information
                );
            processed_data.paymentTerms =
                transformPaymentTermsFromBackendToFrontend({
                    prepayment_percentage: processed_data.prepayment_percentage,
                    payment_terms: processed_data.payment_terms,
                    deliverables_payment_terms:
                        processed_data.deliverables_payment_terms,
                });
            resolve(processed_data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchRFQVendorItemContacts = (
    rfq_event_id: string,
    seller_entity_id: string,
    type: 'FROM_EVENT' | 'DIRECT'
): Promise<IVendorContactDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            if (type === 'FROM_EVENT') {
                let resp = await get<IVendorContactDetails[]>(
                    `/events/rfq/${rfq_event_id}/seller/${seller_entity_id}/items`
                );
                resolve(resp.data);
            } else if (type === 'DIRECT') {
                let resp = await get<IVendorContactDetails[]>(
                    `/events/po_group/${rfq_event_id}/seller/${seller_entity_id}/items`
                );
                resolve(resp.data);
            }
        } catch (err) {
            reject();
        }
    });
};

export const { useFetchRFQVendorItemContactsQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            fetchRFQVendorItemContacts: build.query<
                IVendorContactDetails[],
                {
                    rfq_event_id: string;
                    seller_entity_id: string;
                    type: 'FROM_EVENT' | 'DIRECT';
                }
            >({
                queryFn: async ({ rfq_event_id, seller_entity_id, type }) => {
                    try {
                        if (type === 'FROM_EVENT') {
                            let resp = await get<IVendorContactDetails[]>(
                                `/events/rfq/${rfq_event_id}/seller/${seller_entity_id}/items`
                            );
                            return { data: resp.data };
                        } else if (type === 'DIRECT') {
                            let resp = await get<IVendorContactDetails[]>(
                                `/events/po_group/${rfq_event_id}/seller/${seller_entity_id}/items`
                            );
                            return { data: resp.data };
                        } else {
                            return {
                                error: {
                                    error: new Error('nothing can be done '),
                                },
                            };
                        }
                    } catch (err: any) {
                        return { error: err };
                    }
                },
            }),
        }),
    });

export const fetchRFQItemVendorDetails = (
    rfq_event_id: string,
    rfq_item_entry_id: string
): Promise<IRFQItemAddedVendors[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let vendor_resp = await get<IResponseItemVendors[]>(
                `/events/rfq/${rfq_event_id}/items/${rfq_item_entry_id}/sellers/`
            );

            let processed_data: IRFQItemAddedVendors[] = vendor_resp.data.map(
                (vendor) => ({
                    entity_id: vendor.seller_entity.entity_id,
                    entity_name: vendor.seller_entity.entity_name,
                    entity_logo: vendor.seller_entity.entity_logo[0],
                    entity_website_url:
                        vendor.seller_entity.contacts.websites[0],
                    enterprise_name: vendor.seller_entity.enterprise_name,
                    is_virtual: vendor.seller_entity.is_virtual,
                    verification_status:
                        vendor.seller_entity.verification_status,
                    seller_contacts: vendor.seller_contacts,
                })
            );
            resolve(processed_data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchAllRFQVendors = (
    event_id: string,
    event_type?: 'DIRECT' | 'FROM_EVENT'
): Promise<IGetAllEventSeller[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let vendor_resp = await get<IGetAllEventSeller[]>(
                `/events/${event_id}/sellers/`
            );
            resolve(vendor_resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const { useFetchAllRFQVendorsQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchAllRFQVendors: build.query<
            IGetAllEventSeller[],
            { event_id: string }
        >({
            query: ({ event_id }) => `/events/${event_id}/sellers/`,
        }),
    }),
});

export const fetchLatestSellerItems = (
    event_id: string,
    rfq_item_id: string,
    rfq_item_entry_id: string
): Promise<IRFQVendorWhoOfferedBack[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let vendor_item_resp = await get<IBackendSingleLatestBidResponse[]>(
                `/events/rfq/${event_id}/bids/sellers/items/latest/?rfq_event_item_id=${rfq_item_entry_id}`
            );
            let processed_data: IRFQVendorWhoOfferedBack[] =
                vendor_item_resp.data
                    ?.filter(
                        (vendor) => vendor.rfq_item.rfq_item_id === rfq_item_id
                    )
                    ?.map((vendor) => ({
                        entity_id: vendor.seller_entity.entity_id,
                        entity_name: vendor.seller_entity.entity_name,
                    }));
            resolve(processed_data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchRFQBidDetails = (
    event_id: string,
    seller_id: string
): Promise<IGetBuyerRFQBid[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let bid_resp = await get<any[]>(
                `/events/rfq/${event_id}/bids/items/?enterprise_type=BUYER&seller_entity_id=${seller_id}`
            );
            let processed_data = bid_resp.data;
            for (let bid of processed_data) {
                for (let item of bid.rfq_bid_items) {
                    item.procurement_information =
                        processedProcurementInfoForReceiving(
                            item.procurement_information
                        );
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: item.prepayment_percentage,
                            payment_terms: item.payment_terms,
                            deliverables_payment_terms:
                                item.deliverables_payment_terms,
                        });
                }
            }

            JsonNumsToStrings(processed_data);
            resolve([...processed_data]);
        } catch (err) {
            reject();
        }
    });
};

export const fetchRFQBidDetailsWithOutItems = (
    event_id: string,
    seller_id: string
): Promise<IGetBuyerRFQBidWithoutBidItems[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let bid_resp = await get<any[]>(
                `/events/rfq/${event_id}/bids/?enterprise_type=BUYER&seller_entity_id=${seller_id}`
            );
            let processed_data = bid_resp.data;
            // for (let bid of processed_data) {
            //     for (let item of bid.rfq_bid_items) {
            //         item.procurement_information =
            //             processedProcurementInfoForReceiving(
            //                 item.procurement_information
            //             );
            //         item.paymentTerms =
            //             transformPaymentTermsFromBackendToFrontend({
            //                 prepayment_percentage: item.prepayment_percentage,
            //                 payment_terms: item.payment_terms,
            //                 deliverables_payment_terms:
            //                     item.deliverables_payment_terms,
            //             });
            //     }
            // }

            JsonNumsToStrings(processed_data);
            resolve([...processed_data]);
        } catch (err) {
            reject(err);
        }
    });
};

export const fetchRFQBidDetailsWithOutItemsForAllVendorsApiSlice =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            fetchRFQBidDetailsWithOutItemsForAllVendors: build.query<
                { [sellerEntityId: string]: IGetBuyerRFQBidWithoutBidItems[] },
                { event_id: string }
            >({
                query: ({ event_id }) => ({
                    url: `/events/rfq/${event_id}/bids/?enterprise_type=BUYER`,
                }),
                transformResponse: (
                    baseResponse: IGetBuyerRFQBidWithoutBidItems[]
                ) => {
                    const response: {
                        [
                            sellerEntityId: string
                        ]: IGetBuyerRFQBidWithoutBidItems[];
                    } = {};

                    for (let singleBid of baseResponse) {
                        if (singleBid.seller_entity in response) {
                            response[singleBid.seller_entity].push(singleBid);
                        } else {
                            response[singleBid.seller_entity] = [singleBid];
                        }
                    }

                    return response;
                },
            }),
        }),
    });

export const { useFetchRFQBidDetailsWithOutItemsForAllVendorsQuery } =
    fetchRFQBidDetailsWithOutItemsForAllVendorsApiSlice;

export const fetchRFQBidDetailsWithOutItemsForAllVendors = async (
    event_id: string
) => {
    try {
        const baseResponse = await get<IGetBuyerRFQBidWithoutBidItems[]>(
            `/events/rfq/${event_id}/bids/?enterprise_type=BUYER`
        );
        const response: {
            [sellerEntityId: string]: IGetBuyerRFQBidWithoutBidItems[];
        } = {};

        for (let singleBid of baseResponse.data) {
            if (singleBid.seller_entity in response) {
                response[singleBid.seller_entity].push(singleBid);
            } else {
                response[singleBid.seller_entity] = [singleBid];
            }
        }

        return response;
    } catch (err) {
        throw err;
    }
};

export const addRfqComment = async (
    rfqId: string,
    message: string
): Promise<void> => {
    const addCommentRequest = {
        message: message,
    };
    await post<string, any>(
        `/events/rfq/${rfqId}/comments/create/`,
        addCommentRequest
    );
};

export const getAllRfqComments = async (
    rfqId: string
): Promise<types.GetAllRfqCommentsResponse> => {
    const commentsResponse = await get<any[]>(`/events/rfq/${rfqId}/comments/`);
    const commentList: any[] = commentsResponse.data;
    const comments: any[] = [];

    commentList.forEach((comment) => {
        comments.push({
            message: comment.message,
            datetime: comment.created_datetime,
            userId: comment.user,
            userName: comment.username,
            firstName: comment.first_name,
            lastName: comment.last_name,
        });
    });
    return {
        comments: comments,
    };
};

export const updateEventAccess = async (
    rfqId: string,
    userPermissionList: Array<types.UserEventPermission>
): Promise<void> => {
    const userPermissions: any[] = [];
    userPermissionList.forEach((userPermission) => {
        userPermissions.push({
            user_id: userPermission.userId,
            permissions: userPermission.permissions,
        });
    });
    const updateEventAccessRequest = {
        user_permissions: userPermissions,
    };
    await post<string, any>(
        `/events/rfq/${rfqId}/share/`,
        updateEventAccessRequest
    );
};

export const getAvailableApprovers = async (
    rfqId: string
): Promise<types.GetAvailableApproversResponse> => {
    const getAvailableApproversResponse = await get<any>(
        `/events/rfq/${rfqId}/next_approvers/`
    );
    const availableApproversList: any[] = getAvailableApproversResponse.data;
    const availableApprovers: types.AvailableApprover[] = [];

    availableApproversList.forEach((availableApprover) => {
        availableApprovers.push({
            userId: availableApprover.user,
            userName: availableApprover.username,
            firstName: availableApprover.first_name,
            lastName: availableApprover.last_name,
            userRole: availableApprover.user_role,
        });
    });
    return {
        availableApprovers: availableApprovers,
    };
};

export const getEventApprovals = async (
    rfqId: string
): Promise<types.EventApprovalsResponse> => {
    const eventApprovalsResponse = await get<any>(
        `/events/rfq/${rfqId}/approvers/`
    );
    const eventApprovalsList: any[] = eventApprovalsResponse.data;
    const eventApprovals: types.EventApproval[] = [];

    eventApprovalsList.forEach((eventApproval) => {
        eventApprovals.push({
            requestorId: eventApproval.requestor,
            requestorUserFullName: eventApproval.requestor_name,
            requestorNotes: eventApproval.requestor_notes,
            approverId: eventApproval.approver,
            approverUserFullName: eventApproval.approver_name,
            approverNotes: eventApproval.approver_notes,
            status: eventApproval.status,
        });
    });
    return {
        eventApprovals: eventApprovals,
    };
};

export const sendForApproval = async (
    rfqId: string,
    approverId: string,
    notes: string
): Promise<void> => {
    const request = {
        approver: approverId,
        notes: notes,
        status: 'APPROVAL_PENDING',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const cancelApprovalProcess = async (rfqId: string): Promise<void> => {
    const requestData = {
        status: 'DRAFT',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, requestData);
};

export const rejectEvent = async (
    rfqId: string,
    approverId: string,
    notes: string
): Promise<void> => {
    const request = {
        approver: approverId,
        notes: notes,
        status: 'REJECTED',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const sendForRework = async (
    rfqId: string,
    approverId: string,
    notes: string
): Promise<void> => {
    const request = {
        approver: approverId,
        notes: notes,
        status: 'REWORK',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const approveAndSubmit = async (
    rfqId: string,
    approverId: string,
    notes: string,
    access: string
): Promise<any> => {
    const request = {
        approver: approverId,
        notes: notes,
        status: 'ONGOING',
        access: access,
    };
    const res = await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
    return res;
};

// Live Event Management Operations

export const updateItemTargetPrice = async (
    rfqId: string,
    rfqItemId: string,
    targetPrice: number,
    displayPriceToVendors: boolean
): Promise<void> => {
    const request = {
        desired_price: targetPrice,
        is_price_confidential: displayPriceToVendors,
    };
    await put<string, any>(
        `/events/rfq/${rfqId}/items/${rfqItemId}/price/`,
        request
    );
};

export const updateEventName = async (
    rfqId: string,
    newName: string
): Promise<void> => {
    const request = {
        event_name: newName,
    };
    await put<string, any>(`/events/rfq/${rfqId}/name/`, request);
};

export const updateEventEndDate = async (
    rfqId: string,
    newDate: string
): Promise<void> => {
    const request = {
        event_end_datetime: newDate,
    };
    await put<string, any>(`/events/rfq/${rfqId}/extend/`, request);
};

export const updateItemQuantity = async (
    rfqId: string,
    rfqItemId: string,
    newQuantity: number,
    deliverySchedules: types.DeliverySchedule[]
): Promise<void> => {
    const deliveryScheduleList: any[] = [];
    deliverySchedules.forEach((deliverySchedule) => {
        deliveryScheduleList.push({
            delivery_date: deliverySchedule.deliveryDate,
            quantity: deliverySchedule.quantity,
        });
    });
    const request = {
        quantity: newQuantity,
        delivery_schedule: deliveryScheduleList,
    };
    await put<string, any>(
        `/events/rfq/${rfqId}/items/${rfqItemId}/quantity/`,
        request
    );
};

export const pauseEvent = async (
    rfqId: string,
    notes: string
): Promise<void> => {
    const request = {
        notes: notes,
        status: 'PAUSED',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const unpauseEvent = async (rfqId: string): Promise<void> => {
    const request = {
        status: 'ONGOING',
    };
    await put<string, any>(`/events/rfq/${rfqId}/state/`, request);
};

export const makeNewVersion = (rfqId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let details_resp = await post<string, any>(
                `/events/rfq/${rfqId}/version/`,
                {}
            );
            resolve({
                ...details_resp.data,
            });
        } catch (err) {
            reject();
        }
    });
};

export const cloneRfq = (
    rfqEventId: string,
    newEventName: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let clone_resp: any = await post<string, any>(
                `/events/rfq/${rfqEventId}/clone/`,
                { event_name: newEventName }
            );
            resolve({
                ...clone_resp.data,
            });
        } catch (err: any) {
            if (err.response.data.ErrorCode === 'Exceeded Quota Limit') {
                toast.error(err.response.data.ErrorCode);
            }
            reject();
        }
    });
};

export const updateLiveRFQItemDetails = async (
    rfq_event_id: string,
    rfq_item_id: string,
    quantity: string,
    delivery_schedule: { delivery_date: string | null; quantity: string }[],
    desired_price: string,
    is_price_confidential: boolean,
    shipping_per_unit: string | null,
    discounts: IAdditionalCostsBackend[],
    taxes: IAdditionalCostsBackend[],
    additional_costs: IAdditionalCostsBackend[]
): Promise<any> => {
    const data: any = {
        quantity: quantity,
        delivery_schedule: delivery_schedule,
        desired_price: desired_price,
        is_price_confidential: is_price_confidential,
        total: 0,
    };
    data.shipping_per_unit =
        shipping_per_unit === null || shipping_per_unit === ''
            ? null
            : (+shipping_per_unit).toFixed(2);
    if (taxes) {
        data.taxes = taxes;
    }
    if (discounts) {
        data.discounts = discounts;
    }
    if (additional_costs) {
        data.additional_costs = additional_costs;
    }
    let items_total = calculateNewItemTotalWithDecimalPlaces({
        additional_cost: convertAdditionalCostBackendToFrontend(
            additional_costs ?? []
        ),
        decimalPlaces: 9,
        discount: convertAdditionalCostBackendToFrontend(discounts ?? []),
        item_price: desired_price,
        itemDS: [{ quantity: quantity }],
        tax: convertAdditionalCostBackendToFrontend(taxes ?? []),
    });
    data.total = items_total;

    return new Promise<void>(async (resolve, reject) => {
        try {
            await put<string, any>(
                `/events/rfq/${rfq_event_id}/items/${rfq_item_id}/live/update/`,
                data
            );
            resolve();
        } catch {
            reject();
        }
    });
};

export const updateLiveRFQItemSellers = async (
    rfq_event_id: string,
    rfq_item_id: string,
    sellers_info: {
        seller_entity_id: string;
        vendor_contact_list: string[];
    }[],
    post_to_global_marketplace: boolean
): Promise<any> => {
    await put<string, any>(
        `/events/rfq/${rfq_event_id}/items/${rfq_item_id}/sellers/update/`,
        {
            sellers_info: sellers_info,
            post_to_global_marketplace: post_to_global_marketplace,
            action: 'UPDATE_LIVE_SELLER',
        }
    );
};

export const updateLiveRFQDeliverySchedule = (
    rfq_id: string,
    rfq_item_id: string,
    ds: {
        delivery_date: string;
        quantity: string;
    }[]
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put<
                {
                    delivery_schedule: {
                        delivery_date: string;
                        quantity: string;
                    }[];
                },
                void
            >(`/events/rfq/${rfq_id}/items/${rfq_item_id}/delivery/`, {
                delivery_schedule: ds.map((delivery) => {
                    if (delivery.delivery_date !== null) return delivery;
                    else return { quantity: delivery.quantity };
                }),
            });
            if (resp.status >= 200 && resp.status < 300) resolve();
            else reject();
        } catch (err) {
            reject();
        }
    });
};

export const processedProcurementInfoForSending = (
    procurementInfo: any
): any => {
    if (!procurementInfo) {
        return null;
    }
    let processedProcurementInfoObj = procurementInfo;

    if (
        !processedProcurementInfoObj.lead_time &&
        processedProcurementInfoObj.lead_time !== 0
    ) {
        processedProcurementInfoObj.lead_time = '4';
        processedProcurementInfoObj.lead_time_period = 'WEEKS';
    }
    return processedProcurementInfoObj;
};

export const processedProcurementInfoForReceiving = (
    procurementInfo: any
): any => {
    if (!procurementInfo) {
        return null;
    }
    let prepayment_percentage = procurementInfo.prepayment_percentage ?? '';
    let processedProcurementInfoObj = procurementInfo;
    if (
        prepayment_percentage &&
        !isNaN(+prepayment_percentage) &&
        +prepayment_percentage >= 100
    ) {
        processedProcurementInfoObj.payment_terms = null;
        processedProcurementInfoObj.payment_terms_period = 'DAYS';
        processedProcurementInfoObj.payment_terms_applied_from = null;
    }
    if (
        (!processedProcurementInfoObj.lead_time &&
            +processedProcurementInfoObj.lead_time !== 0) ||
        !processedProcurementInfoObj.lead_time_period
    ) {
        processedProcurementInfoObj.lead_time = '0';
        processedProcurementInfoObj.lead_time_period = 'DAYS';
    }
    return processedProcurementInfoObj;
};

export const postDraftBid = async (
    obj: IPostBuyerRFQBid,
    rfq_event_id: string,
    bid_type: 'PROXY' | 'DIRECT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let processed_obj = obj;
            for (let item of processed_obj.bid_items) {
                item.procurement_information =
                    processedProcurementInfoForSending(
                        item.procurement_information
                    );
            }

            let resp = await post<IPostBuyerRFQBid, any>(
                bid_type === 'DIRECT'
                    ? `/events/rfq/${rfq_event_id}/bids/create/`
                    : `/events/rfq/${rfq_event_id}/bids/proxy_quote/create/`,
                processed_obj
            );

            store.dispatch(
                fetchAllBidDetailsForMultipleVendorApiSlice.endpoints.fetchAllBidDetailsForMultipleVendor.initiate(
                    {
                        rfq_entry_id: rfq_event_id,

                        seller_entity_ids: [],
                    },
                    {
                        forceRefetch: true,
                    }
                )
            );

            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const putDraftBid = async (
    obj: IPostBuyerRFQBid,
    rfq_event_id: string,
    rfq_bid_id: string
): Promise<any> => {
    let processed_obj = obj;
    for (let item of processed_obj.bid_items) {
        item.procurement_information = processedProcurementInfoForSending(
            item.procurement_information
        );
    }

    await put<IPostBuyerRFQBid, any>(
        `/events/rfq/${rfq_event_id}/bids/${rfq_bid_id}/update/`,
        processed_obj
    );
};

export const putDraftBidDetails = async (
    obj: IPostBuyerRFQBid,
    rfq_event_id: string,
    rfq_bid_id: string
): Promise<any> => {
    let processed_obj = obj;
    for (let item of processed_obj.bid_items) {
        item.procurement_information = processedProcurementInfoForSending(
            item.procurement_information
        );
    }

    await put<IPostBuyerRFQBid, any>(
        `/events/rfq/${rfq_event_id}/bids/details/${rfq_bid_id}/update/`,
        processed_obj
    );
};

export const moveDraftQuoteToOngoing = async (
    rfq_event_id: string,
    seller_entity_id: string,
    rfq_bid_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let newObj = {
                seller_entity_id: seller_entity_id,
                action: 'MOVE_DRAFT_COUNTEROFFER_TO_ONGOING',
                notes: '',
            };
            let resp = await put<any, any>(
                `/events/rfq/${rfq_event_id}/bids/${rfq_bid_id}/state/`,
                newObj
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const generateTestVendorQuote = async (
    rfq_event_id: string,
    rfq_bid_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<any, any>(
                `/events/rfq/${rfq_event_id}/bids/${rfq_bid_id}/generate_seller_quote/`,
                {}
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchRFQEventPermissions = (
    event_id: string
): Promise<IAllUserEventPermissions> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<any>(`/events/rfq/${event_id}/permissions/`);
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const moveEventToAwardStage = async (
    rfqId: string,
    approver_id: string
): Promise<void> => {
    const request: any = {
        status: 'AWARD_STAGE',
        approver: approver_id,
        notes: '',
    };
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put<any, any>(
                `/events/rfq/${rfqId}/state/`,
                request
            );
            resolve(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const postBuyerAward = async (
    obj: IPostBuyerAward,
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        if (type === 'DIRECT') {
            try {
                let processed_obj = obj;
                processed_obj.procurement_information =
                    processedProcurementInfoForSending(
                        processed_obj.procurement_information
                    );
                processed_obj.quantity_tolerance_percentage = isEmpty(
                    processed_obj.quantity_tolerance_percentage
                )
                    ? '0'
                    : processed_obj.quantity_tolerance_percentage;
                let resp = await post<IPostBuyerAward, IPurchaseOrderItem>(
                    `/purchase_orders/po_group/items/create/`,
                    processed_obj
                );
                resolve(resp.data);
            } catch (err) {
                reject();
            }
        } else {
            try {
                let processed_obj = obj;
                processed_obj.procurement_information =
                    processedProcurementInfoForSending(
                        processed_obj.procurement_information
                    );
                let resp = await post<IPostBuyerAward, IPurchaseOrderItem>(
                    `/purchase_orders/items/create/`,
                    processed_obj
                );
                resolve(resp.data);
            } catch (err) {
                reject();
            }
        }
    });
};

export const postBuyerAwardLinked = async (
    obj: IPostBuyerAward
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let processed_obj = obj;
            processed_obj.procurement_information =
                processedProcurementInfoForSending(
                    processed_obj.procurement_information
                );
            let resp = await post<IPostBuyerAward, IPurchaseOrderItem>(
                `/purchase_orders/items/linked/create/`,
                processed_obj
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const postBuyerAwardLinkedBulk = async (
    obj: IPostBuyerAward[]
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let processed_obj = obj.map((item) => {
                item.procurement_information =
                    processedProcurementInfoForSending(
                        item.procurement_information
                    );
                return item;
            });
            let resp = await post<IPostBuyerAward[], IPurchaseOrderItem[]>(
                `/purchase_orders/items/linked/create/bulk/`,
                processed_obj
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const putBuyerAwardLinkedBulk = async (
    obj: IPostBuyerAward[]
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let processed_obj = obj.map((item) => {
                item.procurement_information =
                    processedProcurementInfoForSending(
                        item.procurement_information
                    );
                return item;
            });
            let resp = await put<IPostBuyerAward[], IPurchaseOrderItem[]>(
                `/purchase_orders/items/linked/update/bulk/`,
                processed_obj
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const putBuyerAward = async (
    obj: IPostBuyerAward,
    po_item_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let processed_obj = obj;
            processed_obj.procurement_information =
                processedProcurementInfoForSending(
                    processed_obj.procurement_information
                );
            let resp = await put<IPostBuyerAward, IPurchaseOrderItem>(
                `/purchase_orders/items/${po_item_id}/update/`,
                processed_obj
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const putBuyerAwardLinked = async (
    obj: IPostBuyerAward,
    po_item_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let processed_obj = obj;
            processed_obj.procurement_information =
                processedProcurementInfoForSending(
                    processed_obj.procurement_information
                );
            let resp = await put<IPostBuyerAward, IPurchaseOrderItem>(
                `/purchase_orders/items/linked/${po_item_id}/update/`,
                processed_obj
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const deleteAward = async (
    po_item_id: string,
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        if (type === 'DIRECT') {
            try {
                let resp = await del(
                    `/purchase_orders/po_group/items/${po_item_id}/delete/`
                );
                resolve(resp.data);
            } catch (err) {
                reject();
            }
        } else {
            try {
                let resp = await del(
                    `/purchase_orders/items/${po_item_id}/delete/`
                );
                resolve(resp.data);
            } catch (err) {
                reject();
            }
        }
    });
};

export const deleteAwardLinked = async (po_item_id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await del(
                `/purchase_orders/items/linked/${po_item_id}/delete/`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const deleteAwardLinkedBulk = async (eventId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await del(
                `/purchase_orders/items/linked/${eventId}/delete/bulk/`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchAllPoItemsForEvent = (
    event_id: string,
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<IPurchaseOrderItem[]> => {
    return new Promise(async (resolve, reject) => {
        if (type === 'DIRECT') {
            try {
                let resp = await get<any[]>(
                    `/purchase_orders/items?event_id=${event_id}`
                );
                resp.data = resp.data.map((item) => ({
                    ...item,
                    additional_details: {
                        rfq_event_id: item.additional_details.po_group_id,
                        rfq_item_id: item.additional_details.po_group_item_id,
                    },
                }));
                let processed_data = resp.data;
                for (let item of processed_data) {
                    item.procurement_information =
                        processedProcurementInfoForReceiving(
                            item.procurement_information
                        );
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: item.prepayment_percentage,
                            payment_terms: item.payment_terms,
                            deliverables_payment_terms:
                                item.deliverables_payment_terms,
                        });
                }
                JsonNumsToStrings(processed_data);

                let returnValue: IPurchaseOrderItem[] = processed_data;

                // processing for gl project cost center
                for (let item of returnValue) {
                    item.delivery_schedule_items.forEach((dsItem) => {
                        dsItem.general_ledger_id =
                            dsItem.general_ledger?.general_ledger_id ?? null;
                        dsItem.project_id = dsItem.project?.project_id ?? null;
                        dsItem.cost_centre_id =
                            dsItem.cost_centre?.cost_centre_entry_id ?? null;
                    });
                }

                resolve(returnValue);
                //  resolve([])
            } catch (err) {
                reject();
            }
        } else {
            try {
                let resp = await get<IPurchaseOrderItem[]>(
                    `/purchase_orders/items?event_id=${event_id}`
                );
                let processed_data = resp.data;
                for (let item of processed_data) {
                    item.procurement_information =
                        processedProcurementInfoForReceiving(
                            item.procurement_information
                        );
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: (item as any)
                                .prepayment_percentage,
                            payment_terms: (item as any).payment_terms,
                            deliverables_payment_terms: (item as any)
                                .deliverables_payment_terms,
                        });
                }
                JsonNumsToStrings(processed_data);

                let returnValue: IPurchaseOrderItem[] = processed_data;

                // processing for gl project cost center
                for (let item of returnValue) {
                    item.delivery_schedule_items.forEach((dsItem) => {
                        dsItem.general_ledger_id =
                            dsItem.general_ledger?.general_ledger_id ?? null;
                        dsItem.project_id = dsItem.project?.project_id ?? null;
                        dsItem.cost_centre_id =
                            dsItem.cost_centre?.cost_centre_entry_id ?? null;
                    });
                }

                resolve(returnValue);
                //  resolve([])
            } catch (err) {
                reject();
            }
        }
    });
};

export const fetchAllPoItemsLinkedForEvent = (
    event_id: string
): Promise<IPurchaseOrderItem[] | null> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IPurchaseOrderItem[]>(
                `/purchase_orders/items/linked?event_id=${event_id}`
            );
            let processed_data = resp.data;
            for (let item of processed_data) {
                item.procurement_information =
                    processedProcurementInfoForReceiving(
                        item.procurement_information
                    );
                item.paymentTerms = transformPaymentTermsFromBackendToFrontend({
                    prepayment_percentage: (item as any).prepayment_percentage,
                    payment_terms: (item as any).payment_terms,
                    deliverables_payment_terms: (item as any)
                        .deliverables_payment_terms,
                });
            }
            JsonNumsToStrings(processed_data);

            let returnValue: IPurchaseOrderItem[] = processed_data;

            // processing for gl project cost center
            for (let item of returnValue) {
                item.delivery_schedule_items.forEach((dsItem) => {
                    dsItem.general_ledger_id =
                        dsItem.general_ledger?.general_ledger_id ?? null;
                    dsItem.project_id = dsItem.project?.project_id ?? null;
                    dsItem.cost_centre_id =
                        dsItem.cost_centre?.cost_centre_entry_id ?? null;
                });
            }

            resolve(returnValue);
            //  resolve([])
        } catch (err) {
            reject();
        }
    });
};

export const eventCreatorHierarchy = (
    rfq_event_id: string
): Promise<IUserDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<any>(`/events/rfq/${rfq_event_id}/ancestors/`);
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const userIsInCreatorHierarchy = (
    rfq_event_id: string,
    current_user_id: string
): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        eventCreatorHierarchy(rfq_event_id)
            .then((creatorHierarchy) => {
                for (let hierarchyUser of creatorHierarchy) {
                    if (hierarchyUser.user_id === current_user_id) {
                        resolve(true);
                    }
                }
                resolve(false);
            })
            .catch((err) => reject(err));
    });
};

export const getEntityIdentificationNumbers = (
    entity_id: string
): Promise<IEntityIdentification[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IEntityIdentification[]>(
                `/organization/enterprise_entity_identification?entity_id=${entity_id}`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getEntityAddresses = (
    entity_id: string
): Promise<IAddresses[]> => {
    return new Promise<IAddresses[]>(async (resolve, reject) => {
        try {
            let resp = await get<IAddresses[]>(
                `/organization/address/entity/${entity_id}/`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const getVendorEntityAddresses = (
    entity_id: string
): Promise<IVendorAddresses[]> => {
    return new Promise<IVendorAddresses[]>(async (resolve, reject) => {
        try {
            let resp = await get<IVendorAddresses[]>(
                `/organization/address/entity/${entity_id}/`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const editMaxAwardableQuantity = async (
    rfq_item_entry_id: string,
    new_award_qty: string,
    rfq_entry_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put(
                `/events/rfq/${rfq_entry_id}/items/${rfq_item_entry_id}/max_awardable_quantity/update/`,
                { max_awardable_quantity: new_award_qty }
            );
            resolve(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const getSellerContactForBuyerEntity = async (
    buyer_entity_id: string,
    seller_entity_id: string,
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<IVendorContact[]> => {
    return new Promise<IVendorContact[]>(async (resolve, reject) => {
        try {
            let resp = await get<IVendorContact[]>(
                portal === 'VENDOR_MASTER'
                    ? `/organization/vendor_master/admin/entity/${buyer_entity_id}/contacts/?seller_entity_id=${seller_entity_id}`
                    : `/organization/buyer_master/admin/entity/${buyer_entity_id}/contacts/?buyer_entity_id=${seller_entity_id}`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const { useGetSellerContactForBuyerEntityQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            getSellerContactForBuyerEntity: build.query<
                IVendorContact[],
                {
                    buyer_entity_id: string;
                    seller_entity_id: string;
                    portal: 'VENDOR_MASTER' | 'BUYER_MASTER';
                }
            >({
                queryFn: async ({
                    buyer_entity_id,
                    portal,
                    seller_entity_id,
                }) => {
                    try {
                        let resp = await get<IVendorContact[]>(
                            portal === 'VENDOR_MASTER'
                                ? `/organization/vendor_master/admin/entity/${buyer_entity_id}/contacts/?seller_entity_id=${seller_entity_id}`
                                : `/organization/buyer_master/admin/entity/${buyer_entity_id}/contacts/?buyer_entity_id=${seller_entity_id}`
                        );
                        return {
                            data: resp.data,
                        };
                    } catch (err: any) {
                        return {
                            error: err,
                        };
                    }
                },
            }),
        }),
    });

export const getUserEventOnboardingStatus = async (
    role: string
): Promise<{
    [key: string]: {
        [key: string]: boolean;
    };
}> => {
    return new Promise<{
        [key: string]: {
            [key: string]: boolean;
        };
    }>(async (resolve, reject) => {
        try {
            let resp = await get<IEventOnboardingChecklist>(
                `/organization/users/onboarding/checklist?user_type=${role}`
            );
            const onBoardingData: {
                [key: string]: {
                    [key: string]: boolean;
                };
            } = {};
            resp.data.checklist.forEach((item) => {
                const fieldObj: {
                    [key: string]: boolean;
                } = {};
                item.fields.forEach((field) => {
                    const fieldKey = field.key;
                    const fieldStatus = field.selected;
                    fieldObj[fieldKey] = fieldStatus;
                });
                onBoardingData[item.module] = fieldObj;
            });
            resolve(onBoardingData);
        } catch (err) {
            reject();
        }
    });
};

interface SingleBidMultipleBidDetails {
    data: IGetBuyerRFQBidItemDetails[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
        removed: number;
        not_invited: number;
    };
}

export const rfqBidsApiSliceForBuyer = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchRfqSingleBidItemsDetailsBuyer: build.query<
            SingleBidMultipleBidDetails,
            {
                pageNo: number;
                searchText: string;
                rfqBidId: string;
                noOfItemsToRetrive?: number;
                rfq_items_list: string[] | undefined;
                excluded: boolean | undefined;
                seller_list: string[] | undefined;
                selectedBomForFiltering?: string;
                sub_bom_item_id?: string;
                requisition_id?: string;
            }
        >({
            query: ({
                pageNo,
                rfqBidId,
                searchText,
                noOfItemsToRetrive = 10,
                excluded,
                rfq_items_list,
                selectedBomForFiltering,
                sub_bom_item_id,
                requisition_id,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'rfq_bid_item_buyer',
                    items_per_page: noOfItemsToRetrive,
                    page_number: pageNo,
                    search_text: searchText,
                    sort_fields: [],
                    query_data: {
                        rfq_bid_id: rfqBidId,
                        excluded,
                        rfq_items_list,
                        enterprise_bom_id: selectedBomForFiltering,
                        sub_bom_item_id: sub_bom_item_id,
                        requisition_id: requisition_id,
                    },
                    tab: 'all',
                },
            }),
            transformResponse: (
                baseQueryReturnValue: SingleBidMultipleBidDetails
            ) => {
                baseQueryReturnValue.data.forEach((item) => {
                    item.procurement_information =
                        processedProcurementInfoForReceiving(
                            item.procurement_information
                        );
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: (item as any)
                                .prepayment_percentage,
                            payment_terms: (item as any).payment_terms,
                            deliverables_payment_terms: (item as any)
                                .deliverables_payment_terms,
                        });

                    item.delivery_information = item.delivery_schedule;
                });

                JsonNumsToStrings(baseQueryReturnValue);
                baseQueryReturnValue.data = baseQueryReturnValue.data.filter(
                    (itemm) => itemm.bid_item_response_type !== 'NOT_INVITED'
                );

                return baseQueryReturnValue;
            },
        }),
    }),
});

export const {
    useFetchRfqSingleBidItemsDetailsBuyerQuery,
    useLazyFetchRfqSingleBidItemsDetailsBuyerQuery,
} = rfqBidsApiSliceForBuyer;

export const rfqPoLinkInfoUpdate = async (
    fieldsToExport: {
        [RfqStandardSections.EVENT_DETAILS]: ISectionField[];
        [RfqStandardSections.PAYMENT_AND_DELIVERY_TERMS]: ISectionField[];
        [RfqStandardSections.ADDITIONAL_DETAILS]: ISectionField[];
        [RfqStandardSections.ESSENTIAL_TERMS]: ISectionField[];
    } | null,
    rfqId: string
): Promise<void> => {
    try {
        await put(`/events/rfq/${rfqId}/po_linked_fields/update/`, {
            fields_to_be_exported: fieldsToExport,
        });
    } catch (err) {}
};

export const getRfqPoLinkInfo = async (
    rfqId: string
): Promise<{
    EVENT_DETAILS: ISectionField[];
    PAYMENT_AND_DELIVERY_TERMS: ISectionField[];
    ADDITIONAL_DETAILS: ISectionField[];
    ESSENTIAL_TERMS: ISectionField[];
} | null> => {
    try {
        let response = await get(`/events/rfq/${rfqId}/po_linked_fields/`);
        const data = response.data as {
            EVENT_DETAILS: ISectionField[];
            PAYMENT_AND_DELIVERY_TERMS: ISectionField[];
            ADDITIONAL_DETAILS: ISectionField[];
            ESSENTIAL_TERMS: ISectionField[];
        };

        // Check if all sections are empty
        if (Object.values(data).every((section) => section.length === 0)) {
            return null; // Return null if all sections are empty
        }

        return data; // Return the data if any section has content
    } catch (err) {
        return null; // Return null if there's an error
    }
};

export const { useGetRfqExcelDataMutation } = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRfqExcelData: builder.mutation<
            any,
            {
                tab: string;
            }
        >({
            query: ({ tab }) => ({
                url: `/export/rfq/buyer/download/?tab=${tab}`,
                responseHandler: (response) => response.blob(),
            }),
        }),
    }),
});

export const { useGetRfqExcelDataSellerMutation } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getRfqExcelDataSeller: builder.mutation<
                any,
                {
                    tab: string;
                }
            >({
                query: ({ tab }) => ({
                    url: `/export/rfq/seller/download/?tab=${tab}`,
                    responseHandler: (response) => response.blob(),
                }),
            }),
        }),
    });

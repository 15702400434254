import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
} from '@mui/material';
import { ColumnApi } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import {
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IFilterColumnMenuList } from '../ProjectGlCostCenter/constants/projectDashboardConstants';
import { FWMenu, FWMenuItem } from './FWCustomMenu';
import AddIcon from '@mui/icons-material/Add';

import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { IFieldTypeLabel } from '../ProjectGlCostCenter/helpers/projectDashboardHelper';
import {
    DashBoardFilter,
    DashboardSort,
} from '../ProjectGlCostCenter/interface/project.model';
import FWIcon from './FWIcon';
import { FWInput } from './FWInput';
import { FWTypography } from './FWTypograhy';
import { FWButton } from './FWButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { AuthContext } from '../Contexts/AuthContext';
import { FWTooltip } from './FWCustomTooltip';
import { CustomFieldType } from '../Global/Interfaces/TemplateInterface';

type SavedViewsType = { [key: string]: IFilterColumnMenuList[] };

const FWDataGridFilterColumnPopover = ({
    filteredColumnMenuList,
    maximumColumnsSelected,
    gridColumnApi,
    saveDashboardColumnOrder,
    filteredColumns,
    handleColumnMenuList,
    handleColumnMoved,
    dashboardFilters,
    dashboardSort,
    fieldTypeLabels,
    onClose,
    dashboardType,
    updateSavedViews,
    savedViews,
    handleViewChange,
    currentTab,
}: {
    filteredColumnMenuList: IFilterColumnMenuList[];
    maximumColumnsSelected: boolean;
    gridColumnApi: ColumnApi | undefined;
    saveDashboardColumnOrder?: boolean;
    filteredColumns: string[];
    handleColumnMoved: () => void;
    handleColumnMenuList: (
        updatedFilteredColumnMenuList: IFilterColumnMenuList[]
    ) => void;
    dashboardFilters: DashBoardFilter | null;
    dashboardSort: DashboardSort[];
    fieldTypeLabels: IFieldTypeLabel[];
    onClose: () => void;
    dashboardType: string;
    savedViews: SavedViewsType; // Receive savedViews from parent

    updateSavedViews: (
        updatedViews: SavedViewsType,
        isDeleted?: boolean
    ) => void; // Receive updateSavedViews function
    handleViewChange: (viewName: string) => void;
    currentTab: string;
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedColumns, setSelectedColumns] = useState(
        filteredColumnMenuList
    );

    // const [savedViews, setSavedViews] = useState<{ [key: string]: IFilterColumnMenuList[] }>({});
    const [editableView, setEditableView] = useState<string | null>(null);
    const [selectedView, setSelectedView] = useState(
        localStorage.getItem('selectedView') || ''
    );
    const [showNameInput, setShowNameInput] = useState(false);
    const [viewToDelete, setViewToDelete] = useState<string | null>(null);

    const [viewName, setViewName] = useState('New view');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isPresetExpanded, setIsPresetExpanded] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { authData } = useContext(AuthContext);
    const [isCreatingOrSaving, setIsCreatingOrSaving] = useState(false);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const cookieName: string = useMemo(() => {
        return authData.details?.name
            ? `${authData.details?.name}-${dashboardType}-dashboard`
            : `${dashboardType}-dashboard`;
    }, [authData.details?.name, dashboardType]);

    const numberOfViews = Object.keys(savedViews).length;

    const reorderSortRows = useCallback(
        (sourceIndex, destinationIndex) => {
            if (gridColumnApi) {
                let currentColumnState = cloneDeep(
                    gridColumnApi.getColumnState()
                );

                const [shiftedColumn] = currentColumnState.splice(
                    sourceIndex,
                    1
                );
                currentColumnState.splice(destinationIndex, 0, shiftedColumn);
                gridColumnApi.applyColumnState({
                    state: currentColumnState,
                    applyOrder: true,
                });
                const updatedFilterColumnList = cloneDeep(
                    filteredColumnMenuList
                );
                const [shiftedFilterColumn] = updatedFilterColumnList.splice(
                    sourceIndex,
                    1
                );
                updatedFilterColumnList.splice(
                    destinationIndex,
                    0,
                    shiftedFilterColumn
                );
                setHasUnsavedChanges(true); // Mark as unsaved changes
                handleColumnMenuList(updatedFilterColumnList);
                setSelectedColumns(updatedFilterColumnList);
            }
        },
        [filteredColumnMenuList, gridColumnApi, handleColumnMenuList]
    );

    const isFieldFiltered = (fields: string | null) => {
        const isFiltered = dashboardFilters?.and_?.some((filterGroup) => {
            if (filterGroup.single) {
                return filterGroup.single.field === fields;
            }
            if (filterGroup.and_) {
                return filterGroup.and_.some(
                    (subFilter) =>
                        subFilter.single && subFilter.single.field === fields
                );
            }
            return false;
        });

        return isFiltered;
    };

    const isFieldSorted = (fields: string | null) => {
        const isSorted =
            dashboardSort?.some((item) => item.field === fields) ?? false;
        return isSorted;
    };

    const areColumnListsEqual = useCallback(
        (list1: IFilterColumnMenuList[], list2: IFilterColumnMenuList[]) => {
            if (list1.length !== list2.length) return false;
            return list1.every((col1) =>
                list2.some(
                    (col2) =>
                        col1.id === col2.id && col1.checked === col2.checked
                )
            );
        },
        []
    );

    const handleMenuClick = (
        event: React.MouseEvent<HTMLElement>,
        viewName: string
    ) => {
        if (viewName === 'Factwise default view') return; // Prevent opening the menu for "Default view"
        setAnchorEl(event.currentTarget);
        setViewToDelete(viewName);
        setEditableView(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setViewToDelete(null);
        // setEditableView(null);
    };

    const handleEditClick = () => {
        if (viewToDelete && viewToDelete !== 'Factwise default view') {
            handleViewChange(viewToDelete);
            setSelectedColumns(savedViews[viewToDelete]);
            setEditableView(viewToDelete);
            setViewName(viewToDelete); // Set view name to the current view being edited

            handleMenuClose();
        }
    };

    const handleDeleteView = () => {
        if (viewToDelete) {
            const { [viewToDelete]: _, ...rest } = savedViews;

            const newSelectedView = Object.keys(rest)[0] || '';

            localStorage.setItem(cookieName, JSON.stringify(rest));
            // localStorage.setItem('selectedView', newSelectedView);

            updateSavedViews(rest, true);

            handleViewChange(newSelectedView);
            setSelectedColumns(savedViews[newSelectedView]);
            setSelectedView(
                localStorage.getItem('selectedView') || 'Factwise default view'
            );

            setViewToDelete(null);
        }
        handleMenuClose();
    };

    const validateViewName = useCallback(
        (name: string) => {
            const trimmedName = name.trim();
            if (!trimmedName) {
                return 'Please enter a valid name for the view.';
            }
            if (numberOfViews >= 5 && trimmedName !== editableView) {
                return 'You can only create up to five views.';
            }
            const viewNameLowerCase = trimmedName.toLowerCase();
            const viewExists = Object.keys(savedViews).some(
                (existingViewName) =>
                    existingViewName.toLowerCase() === viewNameLowerCase &&
                    existingViewName !== editableView
            );
            if (viewExists) {
                return 'View name already exists.';
            }
            const selectedColumnsForNewView = cloneDeep(selectedColumns);

            const isDuplicate = Object.values(savedViews).some(
                (existingColumns) => {
                    return areColumnListsEqual(
                        existingColumns,
                        selectedColumnsForNewView
                    );
                }
            );

            if (isDuplicate && trimmedName !== editableView) {
                return 'A view with the same column selection already exists.';
            }
            return null;
        },
        [
            areColumnListsEqual,
            editableView,
            numberOfViews,
            savedViews,
            selectedColumns,
        ]
    );

    const validateEditViewName = useCallback(
        (name: string) => {
            const trimmedName = name.trim();
            if (!trimmedName) {
                return 'Please enter a valid name for the view.';
            }
            // if (numberOfViews >= 5 && trimmedName !== editableView) {
            //     return 'You can only create up to five views.';
            // }
            const viewNameLowerCase = trimmedName.toLowerCase();
            const viewExists = Object.keys(savedViews).some(
                (existingViewName) =>
                    existingViewName.toLowerCase() === viewNameLowerCase &&
                    existingViewName !== editableView
            );
            if (viewExists) {
                return 'View name already exists.';
            }
            const selectedColumnsForNewView = cloneDeep(selectedColumns);
            const isDuplicate = Object.entries(savedViews).some(
                ([viewName, existingColumns]) =>
                    viewName !== editableView && // Exclude current view
                    areColumnListsEqual(
                        existingColumns,
                        selectedColumnsForNewView
                    )
            );
            if (isDuplicate) {
                return 'A view with the same column selection already exists.';
            }
            return null;
        },
        [editableView, savedViews, selectedColumns, areColumnListsEqual]
    );

    const handleCheckboxClick = (id: string, checked: boolean, fields: any) => {
        if (gridColumnApi) {
            let colDef = gridColumnApi
                .getAllColumns()
                ?.find((column) => column.getColId() === id);
            if (colDef) {
                gridColumnApi.setColumnVisible(id, !checked);

                let newPrev = cloneDeep(filteredColumnMenuList);
                newPrev = newPrev.map((fcm) =>
                    fcm.id === id ? { ...fcm, checked: !checked } : fcm
                );

                handleColumnMenuList(newPrev);
                setSelectedColumns(newPrev);
                setHasUnsavedChanges(true);
            }
        }
    };

    const handleViewNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsCreatingOrSaving(true);
        const newName = e.target.value;
        setViewName(newName);
        const error = validateViewName(newName);
        setErrorMessage(error);
    };

    const handleEditViewNameChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIsCreatingOrSaving(true);
        const newName = e.target.value;
        setViewName(newName);
        const error = validateEditViewName(newName);
        setErrorMessage(error);
    };

    useEffect(() => {
        if (showNameInput) {
            const error = validateViewName(viewName);
            setErrorMessage(error);
        }
        if (editableView) {
            const error = validateEditViewName(viewName);
            setErrorMessage(error);
        }
    }, [
        selectedColumns,
        viewName,
        savedViews,
        showNameInput,
        validateViewName,
        editableView,
        validateEditViewName,
    ]);

    const saveNewView = () => {
        setIsCreatingOrSaving(true);

        const trimmedViewName = viewName.trim();
        const error = validateViewName(trimmedViewName);

        if (error) {
            setErrorMessage(error);
            setIsCreatingOrSaving(false);
            return;
        }

        const selectedColumnsForNewView = cloneDeep(selectedColumns);
        if (currentTab !== 'ongoing' && dashboardType === 'project') {
            selectedColumnsForNewView.push({
                id: 'submittedBy',
                label: 'Submitted by',
                column: 'Submitted by',
                checked: false,
                sectionName: '',
                isFWField: true,
                isBuiltIn: true,
                fieldType: CustomFieldType.SHORTTEXT,
                fields: 'modified_by_user_name',
                width: undefined,
            });
            selectedColumnsForNewView.push({
                id: 'submittedOn',
                label: 'Submitted on',
                column: 'Submitted on',
                checked: false,
                sectionName: '',
                isFWField: true,
                isBuiltIn: true,
                fieldType: CustomFieldType.DATETIME,
                fields: 'modified_datetime',
                width: undefined,
            });
        }
        const newSavedViews = {
            ...savedViews,
            [trimmedViewName]: selectedColumnsForNewView,
        };

        updateSavedViews(newSavedViews);
        localStorage.setItem(cookieName, JSON.stringify(newSavedViews));

        localStorage.setItem(`${cookieName}-selectedView`, trimmedViewName);

        setSelectedView(trimmedViewName);
        setSelectedColumns(selectedColumnsForNewView);

        setShowNameInput(false);
        setViewName('New view');
        setIsPresetExpanded(true);
        setErrorMessage(null); // Clear any previous error messages
        setIsCreatingOrSaving(false);
        setHasUnsavedChanges(false);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        if (errorMessage) {
            setErrorMessage(null); // Clear any errors related to search
        }
    };

    const saveEditView = () => {
        setIsCreatingOrSaving(true);
        if (!editableView) return;

        const trimmedViewName = viewName.trim();
        const error = validateEditViewName(trimmedViewName);

        if (error) {
            setErrorMessage(error);
            setIsCreatingOrSaving(false);
            return;
        }

        const savedViewsFromStorage = JSON.parse(
            localStorage.getItem(cookieName) || '{}'
        );

        if (!savedViewsFromStorage[editableView]) {
            setErrorMessage('View to edit does not exist.');
            setIsCreatingOrSaving(false);
            return;
        }

        savedViewsFromStorage[editableView] = cloneDeep(selectedColumns);

        if (editableView !== trimmedViewName) {
            const { [editableView]: viewData } = savedViewsFromStorage;
            savedViewsFromStorage[trimmedViewName] = viewData;
            delete savedViewsFromStorage[editableView];
        }

        updateSavedViews(savedViewsFromStorage, true);
        // localStorage.setItem(cookieName, JSON.stringify(savedViewsFromStorage));

        localStorage.setItem(`${cookieName}-selectedView`, trimmedViewName);
        setSelectedView(trimmedViewName);
        setSelectedColumns(savedViewsFromStorage[trimmedViewName]);

        setEditableView(null);
        setViewName('');
        setErrorMessage(null);
        setIsCreatingOrSaving(false);
        setHasUnsavedChanges(false);
    };

    const getUpdateViewErrorMessage = (): string | null => {
        const savedViewsFromStorage: {
            [key: string]: IFilterColumnMenuList[];
        } = JSON.parse(localStorage.getItem(cookieName) || '{}');

        const selectedColumnsForUpdate = cloneDeep(selectedColumns);

        // Check for duplicate column selections, excluding the selected view itself
        const isDuplicate = Object.entries(savedViewsFromStorage).some(
            ([viewName, existingColumns]) =>
                viewName !== selectedView && // Exclude the selected view itself
                areColumnListsEqual(existingColumns, selectedColumnsForUpdate)
        );

        if (isDuplicate) {
            return 'A view with the same column selection already exists.';
        }

        return null;
    };

    const updateViewInLocalStorage = () => {
        const errorMessage = getUpdateViewErrorMessage();
        if (errorMessage) {
            setErrorMessage(errorMessage);
            setIsCreatingOrSaving(false);
            return;
        }

        // Proceed with the update
        const savedViewsFromStorage: {
            [key: string]: IFilterColumnMenuList[];
        } = JSON.parse(localStorage.getItem(cookieName) || '{}');

        savedViewsFromStorage[selectedView] = cloneDeep(selectedColumns);

        try {
            updateSavedViews(savedViewsFromStorage, true);
            localStorage.setItem(`${cookieName}-selectedView`, selectedView);
            setSelectedColumns(savedViewsFromStorage[selectedView]);
            setSelectedView(viewName.trim());
            setEditableView(null);
            setViewName('');
            setErrorMessage(null);
            setIsCreatingOrSaving(false);
            setHasUnsavedChanges(false);
        } catch (error) {
            console.error('Error updating LocalStorage:', error);
            setErrorMessage('Error updating the view.');
            setIsCreatingOrSaving(false);
        }
    };

    const areSelectedColumnsSameAsCurrentView = (): boolean => {
        const savedViewsFromStorage: {
            [key: string]: IFilterColumnMenuList[];
        } = JSON.parse(localStorage.getItem(cookieName) || '{}');

        const currentViewColumns = savedViewsFromStorage[selectedView] || [];
        return areColumnListsEqual(currentViewColumns, selectedColumns);
    };

    const updateViewErrorMessage = getUpdateViewErrorMessage();
    const isSelectedColumnsSameAsCurrentView =
        areSelectedColumnsSameAsCurrentView();

    const handleCancelEdit = () => {
        setViewName('New view');

        // Reset other states
        setEditableView(null);
        setShowNameInput(false);
        setErrorMessage(null);
        setIsCreatingOrSaving(false);
    };

    const handleCancelCreate = () => {
        // Reset to default values or original state
        setViewName('New view');
        setSelectedColumns(filteredColumnMenuList); // Reset to the default column selection

        // Reset other states
        setShowNameInput(false);
        setErrorMessage(null);
        setIsCreatingOrSaving(false);
    };

    useEffect(() => {
        const savedViewsFromStorage = localStorage.getItem(cookieName);
        if (savedViewsFromStorage) {
            const savedViewsData = JSON.parse(savedViewsFromStorage);
            if (!savedViewsData['Factwise default view']) {
                savedViewsData['Factwise default view'] =
                    filteredColumnMenuList;
            }
            updateSavedViews(savedViewsData);
            const savedSelectedView =
                localStorage.getItem(`${cookieName}-selectedView`) ||
                'Factwise default view';
            setSelectedView(savedSelectedView);
        } else {
            updateSavedViews({
                'Factwise default view': filteredColumnMenuList,
            });
            setSelectedView('Factwise default view');
        }
    }, [filteredColumnMenuList, cookieName, updateSavedViews, selectedColumns]);

    useEffect(() => {
        const selectedViewFromStorage = localStorage.getItem(
            `${cookieName}-selectedView`
        );
        setSelectedView(selectedViewFromStorage || 'Factwise default view');
    }, [onClose, cookieName]);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (editableView) {
            inputRef.current?.focus();
        }
    }, [editableView]);

    const isButtonDisabled =
        numberOfViews >= 5 || !!errorMessage || maximumColumnsSelected;

    const [tooltipTitle, setTooltipTitle] = useState('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleValidateAndUpdateTooltip = (viewName: string) => {
        const validationMessage = validateViewName(viewName);
        setTooltipTitle(validationMessage || ''); // Set the tooltip title based on validation
    };

    useEffect(() => {
        handleValidateAndUpdateTooltip(viewName);
    }, [
        viewName,
        numberOfViews,
        savedViews,
        selectedColumns,
        handleValidateAndUpdateTooltip,
    ]);

    const filteredColumnsMenu = selectedColumns.filter(({ label }) =>
        label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    overflow: 'auto',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingX: 1.5,
                        paddingTop: 0.5,
                    }}
                >
                    <FWTypography variant="h5">Columns</FWTypography>
                    <Button
                        onClick={() => setIsPresetExpanded((prev) => !prev)}
                    >
                        Views
                        {isPresetExpanded ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                </Box>

                <Divider sx={{ width: '100%', my: 1 }} />

                {isPresetExpanded && (
                    <FormControl component="fieldset" sx={{ width: '100%' }}>
                        <RadioGroup
                            aria-label="saved-views"
                            name="saved-views"
                            value={selectedView}
                            onChange={(e) => {
                                const viewName = e.target.value;
                                handleViewChange(viewName);
                                setSelectedColumns(savedViews[viewName]);
                                setSelectedView(viewName);
                                // if (savedViews[viewName])
                            }}
                        >
                            {Object.keys(savedViews).map((viewNameMap) => (
                                <Box
                                    key={viewNameMap}
                                    sx={{
                                        width: 'full',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingX: 1.5,
                                    }}
                                >
                                    <FormControlLabel
                                        value={viewNameMap}
                                        control={
                                            <Radio
                                                disabled={
                                                    // Disable if creating a new view or editing
                                                    // !viewNameMap ||
                                                    // viewName === 'New view' ||
                                                    viewNameMap === editableView
                                                }
                                            />
                                        }
                                        label={
                                            <Box
                                                sx={{
                                                    width: 'full',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {editableView ===
                                                viewNameMap ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <FWInput
                                                            value={viewName}
                                                            onKeyDown={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                            onChange={
                                                                handleEditViewNameChange
                                                            }
                                                            placeholder={
                                                                viewName
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            error={Boolean(
                                                                errorMessage
                                                            )}
                                                            helper={{
                                                                text:
                                                                    errorMessage ||
                                                                    '',
                                                            }}
                                                            sx={{ flexGrow: 1 }}
                                                            // inputRef={inputRef}
                                                        />
                                                        <IconButton
                                                            onClick={
                                                                saveEditView
                                                            }
                                                            disabled={
                                                                !viewName ||
                                                                viewName.trim() ===
                                                                    ''
                                                            }
                                                        >
                                                            <Box color="success.main">
                                                                <i className="bi bi-check"></i>
                                                            </Box>
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={
                                                                handleCancelEdit
                                                            }
                                                        >
                                                            <Box color="error.main">
                                                                <i className="bi bi-x"></i>
                                                            </Box>
                                                        </IconButton>
                                                    </Box>
                                                ) : (
                                                    <FWTypography
                                                        sx={{ flexGrow: 1 }}
                                                    >
                                                        {viewNameMap}
                                                    </FWTypography>
                                                )}
                                            </Box>
                                        }
                                    />
                                    {editableView ? null : (
                                        <IconButton
                                            size="small"
                                            sx={{ ml: 1 }}
                                            onClick={(event) =>
                                                handleMenuClick(
                                                    event,
                                                    viewNameMap
                                                )
                                            }
                                            disabled={
                                                viewNameMap ===
                                                'Factwise default view'
                                            }
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}

                            {showNameInput && (
                                <Box
                                    sx={{
                                        width: 'auto',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingX: 1.5,
                                    }}
                                >
                                    {/* <FormControlLabel
                                        value={viewName}
                                        control={<Radio disabled />}
                                        label={ */}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            paddingLeft: 3.5,
                                            alignItems: 'center',
                                            // width: 'full',
                                            // justifyContent:
                                            //     'space-between',
                                        }}
                                    >
                                        <FWInput
                                            sx={{ width: '230px' }}
                                            value={viewName}
                                            onChange={handleViewNameChange}
                                            onKeyDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                            placeholder="New View"
                                            variant="outlined"
                                            size="small"
                                            error={Boolean(errorMessage)}
                                            helper={{
                                                text: errorMessage || '',
                                            }}
                                        />
                                        <IconButton
                                            onClick={saveNewView}
                                            disabled={
                                                // !!errorMessage ||
                                                !viewName ||
                                                viewName.trim() === ''
                                            }
                                        >
                                            {/* Save */}
                                            <Box color="success.main">
                                                <i className="bi bi-check"></i>
                                            </Box>
                                        </IconButton>
                                        <IconButton
                                            onClick={handleCancelCreate}
                                        >
                                            <Box color="error.main">
                                                <i className="bi bi-x"></i>
                                            </Box>
                                        </IconButton>
                                    </Box>
                                    {/* }
                                    /> */}
                                </Box>
                            )}
                        </RadioGroup>

                        {!showNameInput && !editableView && numberOfViews < 5 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    // paddingLeft: 4,
                                    // mb: 1,
                                }}
                            >
                                <FWTooltip
                                    title={tooltipTitle}
                                    disableHoverListener={!isButtonDisabled}
                                >
                                    <span>
                                        <FWButton
                                            onClick={() => {
                                                setShowNameInput(true);
                                                setIsPresetExpanded(true); // Ensure this is set to true when the button is clicked
                                            }}
                                        >
                                            <AddIcon />
                                            Create View
                                        </FWButton>
                                    </span>
                                </FWTooltip>
                                {/* <FWButton onClick={onClose}>Done</FWButton>     */}
                            </Box>
                        )}
                        <Divider sx={{ width: '100%', my: 1 }} />
                    </FormControl>
                )}
            </Box>
            <FWInput
                sx={{ paddingX: 1.5 }}
                value={searchTerm}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
                onChange={handleSearch}
                placeholder="Search..."
                variant="outlined"
            />
            {maximumColumnsSelected ? (
                <FWTypography
                    sx={{
                        color: 'error.main',
                        marginTop: '8px',
                        marginLeft: '3rem',
                        fontSize: '15px',
                    }}
                >
                    Maximum columns are selected
                </FWTypography>
            ) : null}

            {filteredColumnsMenu.length === 0 ? (
                <FWTypography
                    sx={{
                        color: 'gray',
                        width: '100%',
                        height: '100%',
                        paddingY: '100px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        fontSize: '15px',
                    }}
                >
                    No matching field found
                </FWTypography>
            ) : (
                <DragDropContext
                    onDragEnd={(result) => {
                        if (!result.destination) return;
                        reorderSortRows(
                            result.source.index,
                            result.destination.index
                        );
                    }}
                >
                    <Droppable
                        droppableId={
                            'sort-filter-column-popover' +
                            Math.random() +
                            Math.random()
                        }
                    >
                        {(provided) => (
                            <Grid
                                container
                                direction={'column'}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                sx={{
                                    minHeight: '180px',
                                }}
                            >
                                {filteredColumnsMenu
                                    ?.filter(({ label }) => label !== 'Options')
                                    .map(
                                        (
                                            {
                                                checked,
                                                id,
                                                label,
                                                isBuiltIn,
                                                fieldType,
                                                column,
                                                fields,
                                            },
                                            index
                                        ) => {
                                            // const isDefaultView =
                                            //     selectedView ===
                                            //     'Factwise default view';
                                            const columnItem = (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <FWMenuItem
                                                        sx={{
                                                            padding: '0px 8px',
                                                            display: 'flex',
                                                            width: '100%',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                        disableRipple
                                                        disabled={
                                                            (!Boolean(
                                                                showNameInput
                                                            ) &&
                                                                selectedView ===
                                                                    'Factwise default view') ||
                                                            (maximumColumnsSelected &&
                                                                !checked) ||
                                                            ((isFieldFiltered(
                                                                fields
                                                            ) ||
                                                                isFieldSorted(
                                                                    fields
                                                                )) &&
                                                                checked)
                                                        }
                                                        key={id}
                                                        onClick={(e) => {
                                                            if (
                                                                (maximumColumnsSelected &&
                                                                    checked) ||
                                                                !maximumColumnsSelected
                                                            ) {
                                                                e.preventDefault();
                                                                handleCheckboxClick(
                                                                    id,
                                                                    checked,
                                                                    fields
                                                                );
                                                                if (
                                                                    gridColumnApi
                                                                ) {
                                                                    let colDef =
                                                                        gridColumnApi
                                                                            .getAllColumns()
                                                                            ?.find(
                                                                                (
                                                                                    column
                                                                                ) =>
                                                                                    column.getColId() ===
                                                                                    id
                                                                            );
                                                                    if (
                                                                        colDef
                                                                    ) {
                                                                        gridColumnApi.setColumnVisible(
                                                                            id,
                                                                            !checked
                                                                        );

                                                                        if (
                                                                            saveDashboardColumnOrder
                                                                        ) {
                                                                            handleColumnMoved();
                                                                        }

                                                                        let newPrev =
                                                                            cloneDeep(
                                                                                filteredColumnMenuList
                                                                            );

                                                                        newPrev =
                                                                            newPrev.map(
                                                                                (
                                                                                    fcm
                                                                                ) =>
                                                                                    fcm.id ===
                                                                                    id
                                                                                        ? {
                                                                                              ...fcm,
                                                                                              checked:
                                                                                                  !checked ??
                                                                                                  true,
                                                                                          }
                                                                                        : fcm
                                                                            );
                                                                        handleColumnMenuList(
                                                                            newPrev
                                                                        );
                                                                        setSelectedColumns(
                                                                            newPrev
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <Box
                                                            maxWidth={'350px'}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                            onClick={(e) => {
                                                                if (
                                                                    (maximumColumnsSelected &&
                                                                        !checked) ||
                                                                    ((isFieldFiltered(
                                                                        fields
                                                                    ) ||
                                                                        isFieldSorted(
                                                                            fields
                                                                        )) &&
                                                                        checked)
                                                                ) {
                                                                    e.stopPropagation();
                                                                }
                                                            }}
                                                            // onChange={
                                                            //     handleViewNameChange
                                                            // }
                                                        >
                                                            <Checkbox
                                                                size="small"
                                                                checked={
                                                                    checked
                                                                }
                                                                disableRipple
                                                                // disabled={
                                                                //     (maximumColumnsSelected && !checked) ||
                                                                //     isFieldFiltered(fields) ||
                                                                //     isFieldSorted(fields)
                                                                // }
                                                            />
                                                            <FWTypography>
                                                                {label}
                                                            </FWTypography>

                                                            {column &&
                                                                dashboardFilters &&
                                                                isFieldFiltered(
                                                                    fields
                                                                ) && (
                                                                    <Box
                                                                        color="primary.main"
                                                                        sx={{
                                                                            marginLeft:
                                                                                '0.5rem',
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-funnel-fill" />
                                                                    </Box>
                                                                )}

                                                            {column &&
                                                                isFieldSorted(
                                                                    fields
                                                                ) && (
                                                                    <Box
                                                                        color={
                                                                            'primary.main'
                                                                        }
                                                                        sx={{
                                                                            marginLeft:
                                                                                '0.5rem',
                                                                            fontWeight:
                                                                                '900',
                                                                        }}
                                                                    >
                                                                        <FWIcon name="bi bi-arrow-down-up" />
                                                                    </Box>
                                                                )}

                                                            {isBuiltIn && (
                                                                <img
                                                                    style={{
                                                                        height: '1.5rem',
                                                                        width: '1.5rem',
                                                                        marginLeft:
                                                                            '0.7rem',
                                                                    }}
                                                                    src="/logo.png"
                                                                    alt="factwise logo"
                                                                />
                                                            )}

                                                            <FWTypography
                                                                sx={{
                                                                    backgroundColor:
                                                                        '#C7C8CC',
                                                                    borderRadius:
                                                                        '7px !important',
                                                                    fontSize:
                                                                        '0.7rem',
                                                                    marginLeft:
                                                                        '0.5rem',
                                                                    paddingX:
                                                                        '0.3rem',
                                                                    paddingY:
                                                                        '0.1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {fieldType}
                                                            </FWTypography>
                                                        </Box>
                                                    </FWMenuItem>
                                                    <IconButton
                                                        size="small"
                                                        sx={{
                                                            width: '45px',
                                                            height: '45px',
                                                        }}
                                                        disabled={
                                                            label ===
                                                                'Project code' ||
                                                            label ===
                                                                'Event ID' ||
                                                            label ===
                                                                'Item ID' ||
                                                            !checked
                                                        }
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                    >
                                                        <Box>
                                                            <DragIndicatorOutlinedIcon />
                                                        </Box>
                                                    </IconButton>
                                                </div>
                                            );

                                            return label === 'Project code' ||
                                                label === 'Event ID' ||
                                                label === 'Item ID' ||
                                                !checked ? (
                                                <Box key={id}>{columnItem}</Box>
                                            ) : (
                                                <Draggable
                                                    key={id}
                                                    draggableId={id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <Box
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {columnItem}
                                                        </Box>
                                                    )}
                                                </Draggable>
                                            );
                                        }
                                    )}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
            <Box
                sx={{
                    // height: 'full',
                    bgcolor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'sticky',
                    bottom: -2,
                    backgroundColor: 'white',
                    zIndex: 1,
                    pb: 1,
                }}
            >
                <Divider sx={{ width: '100%', mb: 1 }} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        paddingX: 1.5,

                        // mb: 1,
                    }}
                >
                    <FWTooltip
                        title={updateViewErrorMessage || ''}
                        // disableHoverListener={!isButtonDisabled}
                    >
                        <span>
                            <FWButton
                                disabled={
                                    showNameInput ||
                                    !hasUnsavedChanges ||
                                    isCreatingOrSaving ||
                                    selectedView === 'Factwise default view' ||
                                    !!updateViewErrorMessage
                                    // numberOfViews > 5 ||
                                    // isSelectedColumnsSameAsCurrentView
                                }
                                onClick={() => {
                                    updateViewInLocalStorage();
                                }}
                            >
                                Update view
                            </FWButton>
                        </span>
                    </FWTooltip>
                    <FWTooltip
                        title={
                            numberOfViews >= 5
                                ? 'You can only create up to five views'
                                : updateViewErrorMessage || ''
                        }
                    >
                        <span>
                            <FWButton
                                disabled={
                                    showNameInput ||
                                    numberOfViews >= 5 ||
                                    !hasUnsavedChanges ||
                                    isCreatingOrSaving ||
                                    isSelectedColumnsSameAsCurrentView ||
                                    !!updateViewErrorMessage
                                }
                                onClick={() => {
                                    setShowNameInput(true);
                                    setIsPresetExpanded(true); // Ensure this is set to true when the button is clicked
                                }}
                            >
                                Save as
                            </FWButton>
                        </span>
                    </FWTooltip>
                </Box>
            </Box>
            <FWMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <FWMenuItem
                    onClick={handleEditClick}
                    sx={{ color: 'primary.main' }}
                >
                    Edit
                </FWMenuItem>
                <FWMenuItem
                    onClick={handleDeleteView}
                    sx={{ color: 'error.main' }}
                >
                    Delete view
                </FWMenuItem>
            </FWMenu>
        </>
    );
};

export default memo(FWDataGridFilterColumnPopover);

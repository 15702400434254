import { cloneDeep } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getContractDetailsForItem } from '../../../CLM/Services/CLM.service';
import { AuthContext } from '../../../Contexts/AuthContext';
import { IBuyerItemList } from '../../../Events/Interfaces/Buyer/RFQ.model';
import {
    IBackendVendorMasterMultipleItemResponse,
    fetchAllVendorsForRFQItem,
    fetchBuyerEnterpriseItemDetails,
} from '../../../Events/Services/Buyer/RFQ.service';
import { newgetBuyerMasterItemsForEntity } from '../../../Events/Services/Buyer/shared.service';
import { transformPaymentTermsFromBackendToFrontend } from '../../../Global/Helpers/paymentTermsHelpers';
import { IGlobalAppStore } from '../../../Redux/Store';
import { get } from '../../../Utils/api';
import {
    translateCartitemToUpdatePayload,
    translateEnterpriseItemToCartItem,
} from '../../Helpers/itemAnalyticsTemplateHelpers';
import { ItemCartModuleSectionEnum } from '../../Interfaces/ItemAnalyticsInterfaces';
import {
    CartItemDetails,
    NewCartItem,
} from '../../Interfaces/itemCartItemInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import {
    createNewCartItem,
    updateCartItem,
} from '../../Services/ItemAnalytics.service';
import { updateCartDataStore } from '../../Slices/ItemAnalyticsCartSlice';

export default function useCreateNewCartItem(
    cartId: string | null,
    buyerEntityid: string,
    redirectUser: boolean,
    tab: ItemCartModuleSectionEnum
) {
    const [buyerMasterItemList, setBuyerMasterItemList] = useState<
        IBuyerItemList[]
    >([]);

    const { templateDetails } = useContext(ItemCartAccessContext);

    const [itemAddedSuccessfully, setItemAdded] = useState<boolean | null>(
        false
    );

    const toggleAddItem = useCallback((newValue: boolean) => {
        setItemAdded(newValue);
    }, []);

    const [cartEssentials, setCartEssentials] = useState<{
        cart_id: string;
        cart_item_id: string;
    }>({ cart_id: '', cart_item_id: '' });
    const [fetchingItemDetails] = useState(false);

    const { authData } = useContext(AuthContext);

    const defaultCurrencyId = useMemo(() => {
        return authData.details?.enterprise.billing_currency ?? '';
    }, [authData.details?.enterprise.billing_currency]);

    const [hasMoreItemsinList, setHasMoreItemsInList] =
        useState<boolean>(false);

    const [searchItemToAdd, setSearchItemToAdd] = useState({
        searchText: '',
        currentPage: 1,
    });

    const history = useHistory();

    const dispatch = useDispatch();

    const cartDetails = useSelector(
        (store: IGlobalAppStore) => store.ItemCartStore.cartDetails
    );

    const onSearchItem = useCallback(
        (searchText: string) => {
            setSearchItemToAdd({ searchText: searchText, currentPage: 1 });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [itemAddedSuccessfully]
    );
    const onShowMoreItems = useCallback(() => {
        setSearchItemToAdd((prev) => {
            return { ...prev, currentPage: prev.currentPage + 1 };
        });
    }, []);

    const fetchData = useCallback(
        async (currentPage, searchText) => {
            if (!Boolean(buyerEntityid)) {
                return;
            }
            try {
                // setFetchingItemDetails(true);

                let resp = await newgetBuyerMasterItemsForEntity({
                    searchText: searchText,
                    itemsPerPage: 15,
                    pageNumber: currentPage,
                    sortFields: [{ field: 'name', ascending: true }],
                    entityUid: buyerEntityid ?? '',
                });
                if (currentPage === 1) {
                    setBuyerMasterItemList((prev) => [...resp.items]);
                } else {
                    setBuyerMasterItemList((prev) => [
                        ...prev.slice(0, prev.length - 1),
                        ...resp.items,
                    ]);
                }
                setHasMoreItemsInList(
                    resp.pageDetails.totalPages > currentPage
                );
                if (resp.pageDetails.totalPages > currentPage) {
                    const hasNext: IBuyerItemList = {
                        entity_item_id: '',
                        preferred_vendors_count: 0,
                        preferred_vendors: [],
                        enterprise_item: {
                            item_type: 'RAW_MATERIAL',
                            enterprise_item_id: '',
                            code: '',
                            name: '',
                            description: '',
                            buyer_additional_costs: [],
                            seller_additional_costs: [],
                            notes: '',
                            measurement_units: {
                                item_measurement_units: [],
                            },
                            attributes: {
                                attributes: [],
                            },
                            custom_ids: {
                                custom_ids: [],
                            },
                            tags: [],
                            status: 'ACTIVE',
                            buyer_enterprise: '',
                            buyer_pricing_information: null,
                            seller_pricing_information: null,
                        },
                        status: 'ACTIVE',
                        buyer_enterprise: '',
                        buyer_entity: '',
                        hasNext: true,
                    };
                    setBuyerMasterItemList((prev) => [...prev, hasNext]);
                    // setFetchingItemDetails(false);
                }
            } catch (err) {
                console.error(err);
                // setFetchingItemDetails(false);
            }
        },
        [buyerEntityid]
    );
    useEffect(() => {
        fetchData(searchItemToAdd.currentPage, searchItemToAdd.searchText);
        //Disabled cause only want it to run once for search and page change
        //eslint-disable-next-line
    }, [searchItemToAdd]);

    const createNewItem = useCallback(
        async (
            itemPayload: NewCartItem,
            additionalQuantityForCurrentItem?: number
        ) => {
            try {
                let newItemPayload = cloneDeep(itemPayload);

                if (
                    additionalQuantityForCurrentItem &&
                    newItemPayload.quantity !== null
                ) {
                    newItemPayload.quantity =
                        newItemPayload.quantity +
                        additionalQuantityForCurrentItem;
                }

                if (tab === ItemCartModuleSectionEnum.SAVE_FOR_LATER) {
                    newItemPayload.save_for_later = true;
                } else {
                    newItemPayload.save_for_later = false;
                }

                let res = await createNewCartItem(newItemPayload);

                setCartEssentials({
                    cart_id: res.cart_id,
                    cart_item_id: res.cart_item.cart_item_id,
                });

                const updatedStructureOfItems = {
                    ...res.cart_item,
                    paymentTerms: transformPaymentTermsFromBackendToFrontend({
                        prepayment_percentage:
                            res.cart_item.prepayment_percentage,
                        deliverables_payment_terms:
                            res.cart_item.deliverables_payment_terms[0],
                        payment_terms: res.cart_item.payment_terms,
                    }),
                };

                dispatch(
                    updateCartDataStore({
                        type: 'ADD_SINGLE_CART_ITEM',
                        value: updatedStructureOfItems,
                    })
                );

                if (redirectUser) {
                    history.push(
                        `/buyer/carts/${res.cart_id}/${buyerEntityid}/`
                    );
                }

                setItemAdded(true);
                toast.success('Item added successfully!');

                return res;
            } catch (err) {
                setItemAdded(false);

                toast.error('Failed to add item to the cart!');
            }
        },
        [buyerEntityid, dispatch, history, redirectUser, tab]
    );

    const updateItemInCart = useCallback(
        async (
            itemAlreadyPresentInTab: CartItemDetails,
            additionalQuantityForCurrentItem?: number
        ) => {
            try {
                const itemPayload = translateCartitemToUpdatePayload(
                    itemAlreadyPresentInTab,
                    buyerEntityid,
                    cartId,
                    defaultCurrencyId,
                    templateDetails
                );

                let newItemPayload = cloneDeep(itemPayload);

                if (
                    additionalQuantityForCurrentItem &&
                    newItemPayload.quantity !== null
                ) {
                    newItemPayload.quantity =
                        newItemPayload.quantity +
                        additionalQuantityForCurrentItem;
                }

                let res = await updateCartItem(
                    itemAlreadyPresentInTab.cart_item_id,
                    newItemPayload
                );

                const currentItemIndexInStore = cartDetails.findIndex(
                    (cart) => cart.cart_item_id === res.cart_item_id
                );

                dispatch(
                    updateCartDataStore({
                        type: 'UPDATE_SINGLE_CART_ITEM',
                        value: {
                            idx: currentItemIndexInStore,
                            data: res,
                        },
                    })
                );

                if (redirectUser) {
                    history.push(`/buyer/carts/${res.cart}/${buyerEntityid}/`);
                }

                setItemAdded(true);
                toast.success('Item added successfully!');
            } catch (err) {}
        },
        [
            buyerEntityid,
            cartDetails,
            cartId,
            defaultCurrencyId,
            dispatch,
            history,
            redirectUser,
            templateDetails,
        ]
    );

    const fetchAndLoadSelectedItemDetails = useCallback(
        async (
            enterprise_item_id: string,
            itemAlreadyPresentInTab: CartItemDetails | null,
            additionalQuantityForCurrentItem?: number
        ) => {
            try {
                if (itemAlreadyPresentInTab) {
                    let newItemPayload = cloneDeep(itemAlreadyPresentInTab);

                    if (
                        additionalQuantityForCurrentItem &&
                        newItemPayload.quantity !== null
                    ) {
                        newItemPayload.quantity =
                            newItemPayload.quantity +
                            +additionalQuantityForCurrentItem;
                    }

                    updateItemInCart(newItemPayload);
                } else {
                    const allSettledPromises = await Promise.allSettled([
                        fetchBuyerEnterpriseItemDetails(
                            enterprise_item_id,
                            buyerEntityid
                        ),
                        fetchAllVendorsForRFQItem(
                            buyerEntityid,
                            enterprise_item_id
                        ),
                        getContractDetailsForItem(
                            buyerEntityid,
                            enterprise_item_id
                        ),
                        get<IBackendVendorMasterMultipleItemResponse[]>(
                            `/organization/vendor_master/entity/${buyerEntityid}/items/?item_ids[]=${enterprise_item_id}`
                        ),
                    ]);

                    if (
                        allSettledPromises[0].status === 'rejected' ||
                        allSettledPromises[1].status === 'rejected' ||
                        allSettledPromises[2].status === 'rejected' ||
                        allSettledPromises[3].status === 'rejected'
                    ) {
                        throw new Error(
                            "Couldn't fetch required information to create item"
                        );
                    } else {
                        const [
                            itemDirectoryDetails,
                            itemVendorsFromAdmin,
                            listOfcontract,
                        ] = [
                            allSettledPromises[0].value,
                            allSettledPromises[1].value,
                            allSettledPromises[2].value,
                            allSettledPromises[3].value,
                        ];

                        let vendorIDsToBeAdded: string[] = []; // list of vendors to be pre selected
                        let vendorContactsToBeAdded: string[] = []; // list of vendor contacts to be pre selected

                        for (let contract of listOfcontract) {
                            if (
                                !vendorIDsToBeAdded.includes(
                                    contract.seller_entity
                                )
                            ) {
                                vendorIDsToBeAdded = [
                                    contract.seller_entity,
                                    ...vendorIDsToBeAdded,
                                ];

                                if (
                                    contract.vendor_contact_information
                                        ?.vendor_contact_id
                                )
                                    vendorContactsToBeAdded = [
                                        contract.vendor_contact_information
                                            ?.vendor_contact_id ?? '',
                                        ...vendorContactsToBeAdded,
                                    ];
                            }
                        }

                        for (let vendor of itemVendorsFromAdmin) {
                            if (vendor.status === 'PREFERRED') {
                                if (
                                    !vendorIDsToBeAdded.includes(
                                        vendor.seller_entity.entity_id
                                    )
                                )
                                    vendorIDsToBeAdded.push(
                                        vendor.seller_entity.entity_id
                                    );

                                for (let contact of vendor.seller_contacts) {
                                    if (
                                        !vendorContactsToBeAdded.includes(
                                            contact.vendor_contact_id
                                        )
                                    ) {
                                        vendorContactsToBeAdded.push(
                                            contact.vendor_contact_id
                                        );
                                    }
                                }
                            }
                        }
                        for (let contract of listOfcontract) {
                            if (
                                !vendorIDsToBeAdded.includes(
                                    contract.seller_entity
                                )
                            ) {
                                vendorIDsToBeAdded = [
                                    contract.seller_entity,
                                    ...vendorIDsToBeAdded,
                                ];
                                vendorContactsToBeAdded = [
                                    contract.vendor_contact_information
                                        ?.vendor_contact_id ?? '',
                                    ...vendorContactsToBeAdded,
                                ];
                            }
                        }

                        const vendorIds = vendorIDsToBeAdded.map(
                            (vendorIDToBeAdded, idx) => {
                                let vendor_contact_list: string[] = [];

                                if (idx < itemVendorsFromAdmin.length) {
                                    vendor_contact_list = itemVendorsFromAdmin[
                                        idx
                                    ].seller_contacts.map(
                                        (x) => x.vendor_contact_id
                                    );

                                    const secondary =
                                        allSettledPromises[3].status ===
                                        'fulfilled'
                                            ? [
                                                  ...(allSettledPromises[3].value.data
                                                      .find(
                                                          (c) =>
                                                              c.seller_entity
                                                                  .entity_id ===
                                                                  vendorIDToBeAdded &&
                                                              c.buyer_entity ===
                                                                  buyerEntityid
                                                      )
                                                      ?.enterprise_vendor_master.secondary_vendor_contacts.filter(
                                                          (c) =>
                                                              c.buyer_entity ===
                                                              buyerEntityid
                                                      )
                                                      .filter(
                                                          (c) =>
                                                              !itemVendorsFromAdmin[
                                                                  idx
                                                              ].seller_contacts
                                                                  .map(
                                                                      (
                                                                          scemail
                                                                      ) =>
                                                                          scemail.primary_email
                                                                  )
                                                                  .includes(
                                                                      c.primary_email
                                                                  ) &&
                                                              c.is_primary ===
                                                                  false
                                                      )
                                                      .map(
                                                          (c) =>
                                                              c.vendor_contact_id
                                                      ) ?? []),
                                              ]
                                            : [];

                                    vendor_contact_list = Array.from(
                                        new Set<string>([
                                            ...vendor_contact_list,
                                            ...secondary,
                                        ])
                                    );
                                } else {
                                    const contract = listOfcontract.find(
                                        (contract) =>
                                            contract.seller_entity ===
                                            vendorIDToBeAdded
                                    );

                                    if (contract) {
                                        vendor_contact_list = [
                                            contract.vendorMasterDetails[0]
                                                .primary_vendor_contact
                                                .vendor_contact_id,
                                        ];
                                    }
                                }

                                return {
                                    seller_entity_id: vendorIDToBeAdded,
                                    vendor_contact_list,
                                };
                            }
                        );

                        let itemPayload = translateEnterpriseItemToCartItem(
                            itemDirectoryDetails,
                            buyerEntityid,
                            defaultCurrencyId,
                            cartId,
                            templateDetails
                        );

                        itemPayload.seller_entity_ids = vendorIds.map(
                            (vendor) => vendor.seller_entity_id
                        );
                        itemPayload.seller_contact_ids = vendorIds.flatMap(
                            (vendor) =>
                                vendor.vendor_contact_list.map(
                                    (contactId) => contactId
                                )
                        );

                        createNewItem(
                            itemPayload,
                            additionalQuantityForCurrentItem
                        );
                    }
                }
            } catch (err) {
                toast.error('Failed to create item!');
            }
        },
        [
            buyerEntityid,
            cartId,
            createNewItem,
            defaultCurrencyId,
            templateDetails,
            updateItemInCart,
        ]
    );

    return {
        buyerMasterItemList,
        hasMoreItemsinList,
        fetchingItemDetails,
        itemAddedSuccessfully,
        cartEssentials,
        searchItemToAdd,
        onSearchItem,
        onShowMoreItems,
        fetchAndLoadSelectedItemDetails,
        toggleAddItem,
    };
}

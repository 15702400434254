import { cloneDeep, isArray } from 'lodash';
import { templateType } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import {
    convertAdditionalCostStructureForFormula,
    formatCustomFieldsForFormula,
} from '../../Costing/Misc/QuoteHelperFunctions';
import { ICostingCalculatorApiResponse } from '../../Costing/Services/CostingApisCalls.service';
import { fetchMultipleItemAdditionalCost } from '../../Events/RFQAnalytics/Services/exporttoCostCalulator';
import { fetchItemAdditionalCostList } from '../../Events/Services/Buyer/RFQ.service';
// import { IGetFormulaResponse } from '../../Formula/servies/formula.services';
import { CustomTemplateItemTypes } from '../../Global/Interfaces/CustomTemplate.interface';
import { IRFQTemplateSectionItemBackendResponse } from '../../Global/Interfaces/TemplateInterface';
import {
    TokenNode,
    TokenType,
    evaluateTokenNodes,
    getTokenNodes,
} from '../../IfElse/Tokenizers';
import { convertAdditionalCostInterface } from '../../Organizations/Admin/Helpers/ItemHelpers';
import { IPreSelectedAdditionalCostFromApi } from '../../Organizations/Admin/Hooks/ItemDirectory/ItemAdditionalCostHook';
import {
    IITemTemplateFieldResponse,
    getItemTemplateFields,
} from '../../Organizations/Admin/Services/ItemDirectoryService';
import { get, post } from '../../Utils/api';
import { IForumlaWithIfElseAndOperators } from '../Interfaces/AdditionalCostInterfaces';
import {
    getPrimaryEntityId,
    getPrimayEntityCurrencyId,
} from '../Services/additionalCosts.services';
import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
    IAdditionalCost,
    IAdditionalCostInformation,
    IAdditionalCostWithCurrencyAndValue,
    IAdditionalCostsBackend,
    OperandType,
    OperatorType,
} from '../models/AdditionalCost.model';
import { IGetFormulaResponse } from '../../Formula/Models/formula.model';

export interface IUseAdditionalCostAutofill {
    additionalCostId: string;
    templateType: templateType;
    templateId: string;
}

export const getAdditionalCostFromItemTemplate = async () => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let itemTemplateFields = await getItemTemplateFields();
    } catch (err) {}
};

export type getAdditionalCostFromSourceProps = {
    costSource: CostSourceEnum.ITEM_MASTER;
    listOfAdditionalCostFromItemDirectory: IRFQTemplateSectionItemBackendResponse[];
    costId: string;
    itemId: string;
};

export interface IResponseForGetListOfAdditionalCostForAnItem {
    enterprise_item_id: string;
    buyer_additional_costs: Array<IPreSelectedAdditionalCostFromApi>;
    buyer_pricing_information: {
        price: number;
        taxes: any;
        currency_name: string;
        currency_symbol: string;
        additional_costs: any;
        currency_code_id: string;
        currency_code_abbreviation: string;
    };
    seller_additional_costs: Array<IPreSelectedAdditionalCostFromApi>;
    seller_pricing_information: {
        price: number;
        taxes: any;
        currency_name: string;
        currency_symbol: string;
        additional_costs: any;
        currency_code_id: string;
        currency_code_abbreviation: string;
    };
}

export interface IResponseForGetListOfAdditionalCostFromVendor {
    enterprise_vendor_master_id: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    seller_entity: SellerEntity;
    vendor_code: string;
    vendor_name: string;
    status: string;
    seller_address_information: string[];
    seller_identifications: SellerIdentification[];
    notes: string;
    tags: string[];
    buyer_entity_count: number;
    primary_vendor_contact: PrimaryVendorContact;
    created_datetime: string;
    modified_datetime: string;
    custom_fields: CustomFields;
    additional_costs: IPreSelectedAdditionalCostFromApi[];
}

export interface SellerEntity {
    entity_id: string;
    enterprise: string;
    entity_name: string;
    entity_logo: any[];
    entity_description: string | null;
    buyer_status: string;
    seller_status: string;
    contacts: Contacts;
    verification_status: string;
    is_virtual: boolean;
}

export interface Contacts {
    emails: any[];
    websites: any[];
    phone_numbers: any[];
}

export interface SellerIdentification {
    identification_name: string;
    identification_value: string;
}

export interface PrimaryVendorContact {
    vendor_contact_id: string;
    buyer_entity: string | null;
    seller_entity: string;
    full_name: string;
    primary_email: string;
    emails: string[];
    phone_numbers: string[];
    tags: string[];
    is_primary: boolean;
    user: string;
    status: string;
}

export interface CustomFields {}

export interface calculateAdditionalCostInterface {
    additional_cost_id: string | null;
    additional_cost_linkage_id?: string | null;
    costName: string;
    sequence: number;
    costType: CostTypeEnum;
    costValue: string | number;
    allocationType: AllocationTypeEnum | null;
    costSource: CostSourceEnum | null;
    is_calculated: boolean;
    formula?: IForumlaWithIfElseAndOperators | null;
    sourceValue?: string | number;
    sourceCurrencyCodeAbbreviation?: string | null;
    conversionRate?: number | null;
    sourceCurrencySymbol?: string | null;
    source_currency_id?: string | null;
    selected?: boolean;
}

export const getAdditionalCostFromSource = async (
    costInformation: getAdditionalCostFromSourceProps
) => {
    // this stores the list of all additional cost dashboard
    const additionalCostFromDashboard = await fetchItemAdditionalCostList();

    let additionalCostListBasedOnCostSource: IAdditionalCostInformation[] = [];

    let costValue: string = '';

    if (costInformation.costSource === CostSourceEnum.ITEM_MASTER) {
        additionalCostListBasedOnCostSource =
            additionalCostFromDashboard.filter(
                (cost) => cost.cost_source === costInformation.costSource
            );

        // fetch additional cost from itemDirectory
        const additionalCostDataFromAdmin =
            additionalCostListBasedOnCostSource.find(
                (cost) => cost?.additional_cost_id === costInformation.costId
            );
        let itemDetails = await fetchMultipleItemAdditionalCost([
            costInformation.itemId,
        ]);

        const additionalCostForItem =
            itemDetails[costInformation.itemId].buyer_additional_costs;

        const costInItemDirectory = additionalCostForItem?.find(
            (cost) => cost.additional_cost_id === costInformation.costId
        );

        if (
            costInItemDirectory &&
            Boolean(costInItemDirectory.cost_value?.toString())
        ) {
            costValue = costInItemDirectory.cost_value?.toString();
        } else {
            // cost is not there in item directory
            if (additionalCostDataFromAdmin)
                costValue = additionalCostDataFromAdmin?.cost_value?.toString();

            // here is the case when cost is not there in admin
        }
    }

    return costValue;
};

// COST_SOUCE = ITEM
export const checkCostInTemplate = (
    templateType: 'ITEM' | 'VENDOR',
    templateFieldsAndTempateId: {
        templateFields: IITemTemplateFieldResponse;
        template_id: string;
    },
    costId: string
) => {
    try {
        const additionalCostSection =
            templateFieldsAndTempateId?.templateFields?.section_list[0]?.section_items?.find(
                (section) => section.name === 'Additional costs'
            );

        if (additionalCostSection) {
            let additionalCostList =
                templateFieldsAndTempateId?.templateFields?.section_list[0]?.section_items?.filter(
                    (section) =>
                        section.parent_section_item ===
                        additionalCostSection.section_item_id
                );

            let currentCostInformation = additionalCostList.filter(
                (cost) =>
                    cost.additional_information.additional_cost_information
                        ?.additional_cost_id === costId
            );

            if (currentCostInformation) {
                return true;
                // if cost is there in the template, check item directory
            } else {
                // if the cost is not there in the template check admin
                return false;
            }
        }
    } catch (err) {}
};

export const getcostValueForAdditionalCostFromItemDirectory = async (
    itemId: string,
    costId: string
) => {
    try {
        const resp = await post<
            {
                item_ids: string;
            },
            IResponseForGetListOfAdditionalCostForAnItem[]
        >(`/organization/items/additional_costs/`, {
            item_ids: [itemId],
        });

        if (resp?.data) {
            let currentAdditionalCostInItemDirectory =
                resp.data[0].buyer_additional_costs?.find(
                    (cost) => cost.additional_cost.additional_cost_id === costId
                );

            if (currentAdditionalCostInItemDirectory) {
                return currentAdditionalCostInItemDirectory.cost_value;
            } else {
                return null;
            }
        }
        return null;
    } catch (err) {
        //
        return null;
    }
};

// COST_SOUCE = VENDOR

export const checkCostInVendorTemplate = async (costId: string) => {};

export const getCostValueForAdditionalCostFromVendorDirectory = async (
    sellerEntityId: string,
    costId: string
) => {
    try {
        const resp = await get<IResponseForGetListOfAdditionalCostFromVendor[]>(
            `/organization/vendor_master/admin/?seller_entity_id=${sellerEntityId}`
        );

        const primaryEntityId = await getPrimaryEntityId();

        const vendorCurrency = await getPrimayEntityCurrencyId(
            primaryEntityId ?? ''
        );

        if (resp.data) {
            let currentAdditionalCostInVendorDirectory =
                resp.data[0].additional_costs?.find(
                    (cost) => cost.additional_cost.additional_cost_id === costId
                );

            if (currentAdditionalCostInVendorDirectory) {
                return {
                    costValue:
                        currentAdditionalCostInVendorDirectory.cost_value,
                    costCurrency: vendorCurrency,
                };
            } else {
                return null;
            }
        }

        return null;
    } catch (err) {
        //
    }
};

export const autoFillCost = async (
    adminAdditionalCost: IAdditionalCostInformation[],
    checkAdditionalCostPresenceInThisTemplateListToAutofill: ({
        is_required: boolean;
    } & IAdditionalCost)[],
    additionalCostOptions: ({
        is_required: boolean;
        sequence: number;
    } & IAdditionalCost)[],
    buyerCurrencyId: string | null,
    itemId: string,
    sellerEntityId: string,
    itemTemplateDetails: {
        templateFields: IITemTemplateFieldResponse;
        template_id: string;
    },
    vendorTemplateDetails: {
        templateFields: IITemTemplateFieldResponse;
        template_id: string;
    }
) => {
    let additionCostForPayload: IAdditionalCostWithCurrencyAndValue[] = [];

    for (let cost of additionalCostOptions) {
        let foundAddtionalCost:
            | IAdditionalCostWithCurrencyAndValue
            | undefined = undefined;

        const costInformationInAdmin = adminAdditionalCost.find(
            (adminAC) => adminAC.additional_cost_id === cost.additional_cost_id
        );
        const isCostPresentInRFQTemplate =
            checkAdditionalCostPresenceInThisTemplateListToAutofill &&
            checkAdditionalCostPresenceInThisTemplateListToAutofill?.some(
                (rfqCost) =>
                    rfqCost?.additional_cost_id === cost?.additional_cost_id
            );

        // if cost.cost_source is not null
        if (
            cost &&
            costInformationInAdmin &&
            costInformationInAdmin.cost_source &&
            !isCostPresentInRFQTemplate
        ) {
            if (
                costInformationInAdmin.cost_source ===
                    CostSourceEnum.ITEM_MASTER &&
                Boolean(buyerCurrencyId)
            ) {
                // check cost in item template
                const isCostPresentInItemTemplate = checkCostInTemplate(
                    'ITEM',
                    itemTemplateDetails,
                    cost.additional_cost_id ?? ''
                );

                let costValue =
                    await getcostValueForAdditionalCostFromItemDirectory(
                        itemId,
                        cost.additional_cost_id ?? ''
                    );
                // if cost is present in itemTempalte -> check item directory
                if (isCostPresentInItemTemplate && costValue) {
                    // create the payload with item directory value and currency

                    if (cost && cost.costType === CostTypeEnum.PERCENTAGE) {
                        foundAddtionalCost = {
                            costName: cost.costName,
                            sequence: cost.sequence,
                            cost_source: CostSourceEnum.ITEM_MASTER ?? null,
                            currency_id: buyerCurrencyId,
                            additional_cost_id: cost.additional_cost_id,
                            allocationType: null,
                            costType: cost.costType,
                            value: costValue,
                            is_calculated: costInformationInAdmin.is_calculated,
                        };
                    } else if (
                        cost &&
                        cost.costType === CostTypeEnum.ABSOLUTE_VALUE
                    ) {
                        foundAddtionalCost = {
                            costName: cost.costName,
                            sequence: cost.sequence,
                            cost_source: CostSourceEnum.ITEM_MASTER ?? null,
                            currency_id: buyerCurrencyId,
                            allocationType: cost.allocationType!,
                            additional_cost_id: cost.additional_cost_id,
                            costType: cost.costType,
                            value: costValue,
                            is_calculated: costInformationInAdmin.is_calculated,
                        };
                    }
                } else {
                    if (costInformationInAdmin) {
                        if (
                            costInformationInAdmin.cost_type ===
                            CostTypeEnum.PERCENTAGE
                        ) {
                            foundAddtionalCost = {
                                costName: cost.costName,
                                sequence: cost.sequence,
                                cost_source: CostSourceEnum.NONE,
                                currency_id:
                                    costInformationInAdmin.currency ?? null,
                                allocationType: null,
                                additional_cost_id: cost.additional_cost_id,
                                costType: costInformationInAdmin.cost_type,
                                value: costInformationInAdmin.cost_value,
                                is_calculated:
                                    costInformationInAdmin.is_calculated,
                            };
                        } else {
                            foundAddtionalCost = {
                                costName: cost.costName,
                                sequence: cost.sequence,
                                cost_source: CostSourceEnum.NONE,
                                currency_id:
                                    costInformationInAdmin.currency ?? null,
                                additional_cost_id: cost.additional_cost_id,
                                allocationType: cost.allocationType!,
                                costType: costInformationInAdmin.cost_type,
                                value: costInformationInAdmin.cost_value,
                                is_calculated:
                                    costInformationInAdmin.is_calculated,
                            };
                        }
                    }
                }

                if (foundAddtionalCost)
                    additionCostForPayload.push(foundAddtionalCost);
            } else if (
                costInformationInAdmin.cost_source ===
                CostSourceEnum.VENDOR_MASTER
            ) {
                const isCostPresentInVendorTemplate = checkCostInTemplate(
                    'VENDOR',
                    vendorTemplateDetails,
                    cost.additional_cost_id ?? ''
                );

                // bidinfo[singleBidInfoKey][0]?.sellerEntity.entityId,
                const costValueFromVendorDir =
                    await getCostValueForAdditionalCostFromVendorDirectory(
                        sellerEntityId,
                        cost.additional_cost_id ?? ''
                    );
                if (isCostPresentInVendorTemplate && costValueFromVendorDir) {
                    if (costValueFromVendorDir === null) {
                        if (cost && cost.costType === CostTypeEnum.PERCENTAGE) {
                            foundAddtionalCost = {
                                sequence: cost.sequence,
                                costName: cost.costName,
                                cost_source: CostSourceEnum.NONE,
                                currency_id: costInformationInAdmin.currency,
                                additional_cost_id: cost.additional_cost_id,
                                allocationType: null,
                                costType: cost.costType,
                                value: costInformationInAdmin.cost_value,
                                is_calculated:
                                    costInformationInAdmin.is_calculated,
                            };
                        } else if (
                            cost &&
                            cost.costType === CostTypeEnum.ABSOLUTE_VALUE
                        ) {
                            foundAddtionalCost = {
                                costName: cost.costName,
                                sequence: cost.sequence,
                                cost_source: CostSourceEnum.NONE,
                                currency_id: costInformationInAdmin.currency,
                                allocationType: cost.allocationType!,
                                additional_cost_id: cost.additional_cost_id,
                                costType: cost.costType,
                                value: costInformationInAdmin.cost_value,
                                is_calculated:
                                    costInformationInAdmin.is_calculated,
                            };
                        }
                    } else {
                        if (cost && cost.costType === CostTypeEnum.PERCENTAGE) {
                            foundAddtionalCost = {
                                costName: cost.costName,
                                sequence: cost.sequence,
                                cost_source: CostSourceEnum.VENDOR_MASTER,
                                currency_id:
                                    costValueFromVendorDir.costCurrency,
                                additional_cost_id: cost.additional_cost_id,
                                allocationType: null,
                                costType: cost.costType,
                                value: costValueFromVendorDir.costValue,
                                is_calculated:
                                    costInformationInAdmin.is_calculated,
                            };
                        } else if (
                            cost &&
                            cost.costType === CostTypeEnum.ABSOLUTE_VALUE
                        ) {
                            foundAddtionalCost = {
                                costName: cost.costName,
                                sequence: cost.sequence,
                                cost_source: CostSourceEnum.VENDOR_MASTER,
                                currency_id:
                                    costValueFromVendorDir.costCurrency,
                                allocationType: cost.allocationType!,
                                additional_cost_id: cost.additional_cost_id,
                                costType: cost.costType,
                                value: costValueFromVendorDir.costValue,
                                is_calculated:
                                    costInformationInAdmin.is_calculated,
                            };
                        }
                    }
                } else {
                    if (
                        costInformationInAdmin.cost_source ===
                        CostSourceEnum.VENDOR_MASTER
                    ) {
                        if (
                            costInformationInAdmin.cost_type ===
                            CostTypeEnum.PERCENTAGE
                        ) {
                            foundAddtionalCost = {
                                costName: cost.costName,
                                sequence: cost.sequence,
                                cost_source: CostSourceEnum.NONE,
                                currency_id:
                                    costInformationInAdmin.currency ?? null,
                                allocationType: null,
                                additional_cost_id: cost.additional_cost_id,
                                costType: cost.costType,
                                value: costInformationInAdmin.cost_value,
                                is_calculated:
                                    costInformationInAdmin.is_calculated,
                            };
                        } else {
                            foundAddtionalCost = {
                                costName: cost.costName,
                                sequence: cost.sequence,
                                cost_source: CostSourceEnum.NONE,
                                currency_id:
                                    costInformationInAdmin.currency ?? null,
                                additional_cost_id: cost.additional_cost_id,
                                allocationType: cost.allocationType!,
                                costType: cost.costType,
                                value: costInformationInAdmin.cost_value,
                                is_calculated:
                                    costInformationInAdmin.is_calculated,
                            };
                        }
                    }
                }
                if (foundAddtionalCost)
                    additionCostForPayload.push(foundAddtionalCost);
            } else if (
                costInformationInAdmin.cost_source === CostSourceEnum.NONE ||
                (costInformationInAdmin.cost_source ===
                    CostSourceEnum.ITEM_MASTER &&
                    !Boolean(buyerCurrencyId))
            ) {
                if (
                    costInformationInAdmin.cost_type === CostTypeEnum.PERCENTAGE
                ) {
                    foundAddtionalCost = {
                        costName: cost.costName,
                        sequence: cost.sequence,
                        cost_source: CostSourceEnum.NONE,
                        currency_id: costInformationInAdmin.currency ?? null,
                        allocationType: null,
                        additional_cost_id: cost.additional_cost_id,
                        costType: cost.costType,
                        value: costInformationInAdmin.cost_value,
                        is_calculated: costInformationInAdmin.is_calculated,
                    };
                } else {
                    foundAddtionalCost = {
                        costName: cost.costName,
                        sequence: cost.sequence,
                        cost_source: CostSourceEnum.NONE,
                        currency_id: costInformationInAdmin.currency ?? null,
                        additional_cost_id: cost.additional_cost_id,
                        allocationType: cost.allocationType!,
                        costType: cost.costType,
                        value: costInformationInAdmin.cost_value,
                        is_calculated: costInformationInAdmin.is_calculated,
                    };
                }
                if (foundAddtionalCost)
                    additionCostForPayload.push(foundAddtionalCost);
            }
        }

        if (cost.cost_source === CostSourceEnum.FORMULA)
            additionCostForPayload.push({
                costName: cost.costName,
                sequence: cost.sequence,
                cost_source: CostSourceEnum.FORMULA,
                currency_id: null,
                additional_cost_id: cost.additional_cost_id,
                allocationType:
                    cost.costType === CostTypeEnum.ABSOLUTE_VALUE
                        ? cost.allocationType!
                        : null,
                costType: cost.costType,
                value: 0,
                is_calculated: costInformationInAdmin?.is_calculated,
            });
    }

    return additionCostForPayload;
};

export const getAdditionalCostFromVendorDirectoryForExportToQuote = async (
    sellerEntityId: string,
    sellerEntityIdForExistingQuote: string
) => {
    try {
        const resp = await get<IResponseForGetListOfAdditionalCostFromVendor[]>(
            `/organization/vendor_master/admin/?seller_entity_id=${sellerEntityId}`
        );

        // const primaryEntityId = await getPrimaryEntityId();

        const vendorCurrency = await getPrimayEntityCurrencyId(
            sellerEntityIdForExistingQuote ?? ''
        );
        return {
            additionalCosts: convertAdditionalCostInterface(
                resp.data[0].additional_costs
            ),
            vendorCurrencyIdForCurrentBid: vendorCurrency,
        };
    } catch (err) {
        //
        return null;
    }
};

export const autoFillForExportToQuote = async (
    adminAdditionalCost: IAdditionalCostInformation[],
    additionalCostToBeAutoFilled: ({
        is_required: boolean;
    } & IAdditionalCost)[],
    additionalCostsInRFQTemplate: ({
        is_required: boolean;
    } & IAdditionalCost)[],
    itemIdForCurrentBid: string,
    itemDirectoryDetails: {
        additionalCosts: IAdditionalCostsBackend[];
        buyerCurrencyIdForCurrentBid: string | null;
    },
    vendorDirectoryDetails: {
        additionalCosts: IAdditionalCostsBackend[];
        vendorCurrencyIdForCurrentBid: string | null;
    },
    itemTemplateDetails: {
        templateFields: IITemTemplateFieldResponse;
        template_id: string;
    },
    vendorTemplateDetails: {
        templateFields: IITemTemplateFieldResponse;
        template_id: string;
    }
) => {
    let additionCostForPayload: IAdditionalCostWithCurrencyAndValue[] = [];

    additionalCostToBeAutoFilled.forEach((costToBeAutoFilled) => {
        let foundAddtionalCost:
            | IAdditionalCostWithCurrencyAndValue
            | undefined = undefined;

        const costInformationFromAdmin = adminAdditionalCost.find(
            (adminAC) =>
                adminAC.additional_cost_id ===
                costToBeAutoFilled.additional_cost_id
        );

        const isCostPresentInRFQTemplate =
            additionalCostsInRFQTemplate &&
            additionalCostsInRFQTemplate?.some(
                (rfqCost) =>
                    rfqCost?.additional_cost_id ===
                    costToBeAutoFilled?.additional_cost_id
            );

        const allowCostToBeAutofilled =
            costInformationFromAdmin &&
            !isCostPresentInRFQTemplate &&
            costInformationFromAdmin.cost_source;

        if (allowCostToBeAutofilled) {
            if (
                costInformationFromAdmin.cost_type === CostTypeEnum.PERCENTAGE
            ) {
                foundAddtionalCost = {
                    costName: costInformationFromAdmin.cost_name,
                    cost_source: CostSourceEnum.NONE,
                    sequence: cloneDeep(additionCostForPayload).length + 1,
                    currency_id: costInformationFromAdmin.currency,
                    additional_cost_id:
                        costInformationFromAdmin.additional_cost_id,
                    allocationType: null,
                    costType: costInformationFromAdmin.cost_type,
                    value: costInformationFromAdmin.cost_value,
                    is_calculated: costInformationFromAdmin.is_calculated,
                };
            } else if (
                costInformationFromAdmin.cost_type ===
                CostTypeEnum.ABSOLUTE_VALUE
            ) {
                foundAddtionalCost = {
                    costName: costInformationFromAdmin.cost_name,
                    cost_source: CostSourceEnum.NONE,
                    sequence: cloneDeep(additionCostForPayload).length + 1,
                    currency_id: costInformationFromAdmin.currency,
                    additional_cost_id:
                        costInformationFromAdmin.additional_cost_id,
                    allocationType: costInformationFromAdmin.allocation_type,
                    costType: CostTypeEnum.ABSOLUTE_VALUE,
                    value: costInformationFromAdmin.cost_value,
                    is_calculated: costInformationFromAdmin.is_calculated,
                };
            }
            // for costSource Item

            if (
                costInformationFromAdmin.cost_source ===
                CostSourceEnum.ITEM_MASTER
            ) {
                const costInfoFromItemDir =
                    itemDirectoryDetails.additionalCosts?.find(
                        (itemAC) =>
                            itemAC.additional_cost_id ===
                            costToBeAutoFilled.additional_cost_id
                    );

                const isCostPresentInItemTemplate = checkCostInTemplate(
                    'ITEM',
                    itemTemplateDetails,
                    costToBeAutoFilled.additional_cost_id ?? ''
                );

                if (
                    isCostPresentInItemTemplate &&
                    ((costInformationFromAdmin.cost_type ===
                        CostTypeEnum.ABSOLUTE_VALUE &&
                        itemDirectoryDetails.buyerCurrencyIdForCurrentBid) ||
                        costInformationFromAdmin.cost_type ===
                            CostTypeEnum.PERCENTAGE) &&
                    costInfoFromItemDir
                ) {
                    if (
                        costInformationFromAdmin.cost_type ===
                        CostTypeEnum.PERCENTAGE
                    ) {
                        foundAddtionalCost = {
                            costName: costInfoFromItemDir.cost_name,
                            sequence:
                                cloneDeep(additionCostForPayload).length + 1,
                            cost_source: CostSourceEnum.ITEM_MASTER,
                            currency_id:
                                itemDirectoryDetails.buyerCurrencyIdForCurrentBid ??
                                null,
                            additional_cost_id:
                                costInfoFromItemDir.additional_cost_id,
                            allocationType: null,
                            costType: CostTypeEnum.PERCENTAGE,
                            value: costInfoFromItemDir?.cost_value,
                            is_calculated:
                                costInformationFromAdmin.is_calculated,
                        };
                    } else if (
                        costInformationFromAdmin.cost_type ===
                        CostTypeEnum.ABSOLUTE_VALUE
                    ) {
                        foundAddtionalCost = {
                            costName: costInfoFromItemDir.cost_name,
                            sequence:
                                cloneDeep(additionCostForPayload).length + 1,
                            cost_source: CostSourceEnum.ITEM_MASTER,
                            currency_id:
                                itemDirectoryDetails.buyerCurrencyIdForCurrentBid ??
                                null,
                            allocationType:
                                costInfoFromItemDir.allocation_type!,
                            additional_cost_id:
                                costInfoFromItemDir.additional_cost_id,
                            costType: CostTypeEnum.ABSOLUTE_VALUE,
                            value: costInfoFromItemDir.cost_value,
                            is_calculated:
                                costInformationFromAdmin.is_calculated,
                        };
                    }
                }
            } else if (
                costInformationFromAdmin.cost_source ===
                CostSourceEnum.VENDOR_MASTER
            ) {
                const costInfoFromVendorDir =
                    vendorDirectoryDetails.additionalCosts?.find(
                        (itemAC) =>
                            itemAC.additional_cost_id ===
                            costToBeAutoFilled.additional_cost_id
                    );

                const isCostPresentInVendorTemplate = checkCostInTemplate(
                    'VENDOR',
                    vendorTemplateDetails,
                    costToBeAutoFilled.additional_cost_id ?? ''
                );

                if (
                    isCostPresentInVendorTemplate &&
                    ((costInformationFromAdmin.cost_type ===
                        CostTypeEnum.ABSOLUTE_VALUE &&
                        vendorDirectoryDetails.vendorCurrencyIdForCurrentBid) ||
                        costInformationFromAdmin.cost_type ===
                            CostTypeEnum.PERCENTAGE) &&
                    costInfoFromVendorDir
                ) {
                    if (
                        costInfoFromVendorDir.cost_type ===
                        CostTypeEnum.PERCENTAGE
                    ) {
                        foundAddtionalCost = {
                            costName: costInfoFromVendorDir.cost_name,
                            sequence:
                                cloneDeep(additionCostForPayload).length + 1,
                            cost_source: CostSourceEnum.VENDOR_MASTER,
                            currency_id:
                                vendorDirectoryDetails.vendorCurrencyIdForCurrentBid ??
                                null,
                            additional_cost_id:
                                costInfoFromVendorDir.additional_cost_id,
                            allocationType: null,
                            costType: CostTypeEnum.PERCENTAGE,
                            value: costInfoFromVendorDir?.cost_value,
                            is_calculated:
                                costInformationFromAdmin.is_calculated,
                        };
                    } else if (
                        costInfoFromVendorDir.cost_type ===
                        CostTypeEnum.ABSOLUTE_VALUE
                    ) {
                        foundAddtionalCost = {
                            costName: costInfoFromVendorDir.cost_name,
                            sequence:
                                cloneDeep(additionCostForPayload).length + 1,
                            cost_source: CostSourceEnum.VENDOR_MASTER,
                            currency_id:
                                vendorDirectoryDetails.vendorCurrencyIdForCurrentBid ??
                                null,
                            allocationType:
                                costInfoFromVendorDir.allocation_type!,
                            additional_cost_id:
                                costInfoFromVendorDir.additional_cost_id,
                            costType: CostTypeEnum.ABSOLUTE_VALUE,
                            value: costInfoFromVendorDir.cost_value,
                            is_calculated:
                                costInformationFromAdmin.is_calculated,
                        };
                    }
                }
            } else if (
                costInformationFromAdmin.cost_source === CostSourceEnum.FORMULA
            ) {
                foundAddtionalCost = {
                    costName: costInformationFromAdmin.cost_name,
                    sequence: cloneDeep(additionCostForPayload).length + 1,
                    cost_source: CostSourceEnum.FORMULA,
                    allocationType: costInformationFromAdmin.allocation_type,
                    additional_cost_id:
                        costInformationFromAdmin.additional_cost_id,
                    costType: costInformationFromAdmin.cost_type,
                    value: costInformationFromAdmin.cost_value,
                    currency_id: null,
                    formula: costInformationFromAdmin.formula?.formula,
                    is_calculated: costInformationFromAdmin.is_calculated,
                };
            }
            if (foundAddtionalCost) {
                additionCostForPayload.push(foundAddtionalCost);
            }
        }
    });

    return additionCostForPayload;
};

const recursivelyCalculateAdditionalCostForFormula = (
    additionalCostFormulaCalculated: {
        [additionalCostName: string]: {
            calculated: boolean;
            costValue?: number | undefined;
        };
    },
    additionalCost: calculateAdditionalCostInterface,
    additionalCosts: calculateAdditionalCostInterface[],
    additionalCostForPayload: calculateAdditionalCostInterface[],
    custom_fields: {
        name: string;
        value: string | boolean | string[] | null;
        type: CustomTemplateItemTypes;
    }[],
    currencyAbbreviation: string,
    quantity?: number | null,
    targetRate?: number | null,
    adminAdditionalCost?: IAdditionalCostInformation[],
    is_log?: boolean
): {
    additionalCostForPayload: calculateAdditionalCostInterface[];
    additionalCostFormulaCalculated: {
        [additionalCostName: string]: {
            calculated: boolean;
            costValue?: number | undefined;
        };
    };
} => {
    let formulaString = '';
    let formula = null;
    let costInformationInAdmin = null;

    costInformationInAdmin = adminAdditionalCost?.find(
        (adminAC) =>
            adminAC.additional_cost_id === additionalCost.additional_cost_id
    );
    if (costInformationInAdmin)
        formula = costInformationInAdmin?.formula?.formula;
    else formula = additionalCost.formula;

    if (
        additionalCostFormulaCalculated[
            additionalCost.costName.toLowerCase()
        ] &&
        additionalCostFormulaCalculated[additionalCost.costName.toLowerCase()]
            .calculated
    )
        return {
            additionalCostForPayload: additionalCostForPayload,
            additionalCostFormulaCalculated: additionalCostFormulaCalculated,
        };

    formulaString = formula?.formula_string ?? '';

    let calcValue: string | undefined | number = undefined;

    if (formulaString !== '') {
        // const tokens = getTokens(formulaString);
        const tokenNodes = getTokenNodes(formulaString);

        for (let token of getListOfReferencesname(tokenNodes)) {
            if (
                token in additionalCostFormulaCalculated &&
                additionalCostFormulaCalculated[token.toLowerCase()] &&
                additionalCostFormulaCalculated[token.toLowerCase()]
                    .calculated === false
            ) {
                const foundAdditionalCost = additionalCosts.find(
                    (additionalCost) =>
                        additionalCost.costName.trim().toLowerCase() ===
                        token.toLowerCase()
                );
                if (foundAdditionalCost) {
                    let temp = recursivelyCalculateAdditionalCostForFormula(
                        additionalCostFormulaCalculated,
                        foundAdditionalCost,
                        additionalCosts,
                        additionalCostForPayload,
                        custom_fields,
                        currencyAbbreviation,
                        quantity,
                        targetRate,
                        adminAdditionalCost,
                        is_log
                    );

                    additionalCostFormulaCalculated =
                        temp.additionalCostFormulaCalculated;
                    additionalCostForPayload = temp.additionalCostForPayload;
                }
            }
        }

        calcValue = evaluateTokenNodes(tokenNodes, (v: string) => {
            if (v.toLowerCase() === 'target rate') {
                return targetRate?.toString() ?? '';
            }
            if (v.toLowerCase() === 'quantity') {
                return quantity?.toString() ?? '';
            }

            if (v.toLowerCase() === 'currency') {
                return currencyAbbreviation;
            }

            if (
                (v in additionalCostFormulaCalculated ||
                    v.toLowerCase() in additionalCostFormulaCalculated) &&
                (additionalCostFormulaCalculated[v]?.calculated ||
                    additionalCostFormulaCalculated[v.toLowerCase()]
                        ?.calculated)
            ) {
                const value =
                    additionalCostFormulaCalculated[v]?.costValue ??
                    additionalCostFormulaCalculated[v.toLowerCase()]
                        ?.costValue ??
                    '';

                if (value) {
                    return value.toString();
                }
            }
            const foundCustomFields = custom_fields.find(
                (cf) => cf.name.toLowerCase().trim() === v.toLowerCase().trim()
            );

            if (foundCustomFields && foundCustomFields.value) {
                if (isArray(foundCustomFields.value)) {
                    return `[${foundCustomFields.value.join(', ')}]`;
                }

                return foundCustomFields.value.toString();
            }

            return '';
        });
    } else if (additionalCosts.length === -1) {
        calcValue = Infinity;
    }

    let OldFormulaString = '';
    formula?.operands?.forEach((element) => {
        if (element.operator_type) {
            if (element.operator_type === OperatorType.ADD)
                OldFormulaString += '+';
            else if (element.operator_type === OperatorType.SUBTRACT)
                OldFormulaString += '-';
            else if (element.operator_type === OperatorType.MULTIPLY)
                OldFormulaString += '*';
            else if (element.operator_type === OperatorType.DIVIDE)
                OldFormulaString += '/';
            else if (element.operator_type === OperatorType.OPENING_PARANTHESIS)
                OldFormulaString += '(';
            else if (element.operator_type === OperatorType.CLOSING_PARANTHESIS)
                OldFormulaString += ')';
            else OldFormulaString += '^';
        } else if (element.operand_type) {
            if (element.operand_type === OperandType.NUMBER)
                OldFormulaString += element.value?.toString();
            else if (element.operand_type === OperandType.FIELD) {
                if (element.field === 'Quantity') {
                    OldFormulaString += quantity?.toString();
                } else if (element.field === 'Target Rate') {
                    OldFormulaString += targetRate?.toString();
                } else if (element.field === 'CUSTOM_FIELD') {
                    let flag = false;
                    const field = custom_fields.find(
                        (field) =>
                            field.name.trim().toLowerCase() ===
                            element.field_name?.trim().toLowerCase()
                    );
                    if (field) {
                        OldFormulaString += field.value ? field.value : '0';
                        flag = true;
                    }
                    if (!flag) OldFormulaString += '0';
                } else if (element.field === 'ADDITIONAL_COST') {
                    const foundAdditionalCost = additionalCosts.find(
                        (additionalCost) =>
                            additionalCost.costName.trim().toLowerCase() ===
                            element.field_name?.trim().toLowerCase()
                    );
                    if (
                        foundAdditionalCost?.costSource ===
                        CostSourceEnum.FORMULA
                    ) {
                        if (
                            !additionalCostFormulaCalculated[
                                foundAdditionalCost.costName
                            ]
                        ) {
                            let temp =
                                recursivelyCalculateAdditionalCostForFormula(
                                    additionalCostFormulaCalculated,
                                    foundAdditionalCost,
                                    additionalCosts,
                                    additionalCostForPayload,
                                    custom_fields,
                                    currencyAbbreviation,
                                    quantity,
                                    targetRate,
                                    adminAdditionalCost,
                                    is_log
                                );

                            additionalCostFormulaCalculated =
                                temp.additionalCostFormulaCalculated;
                            additionalCostForPayload =
                                temp.additionalCostForPayload;
                        }
                        const newAdditionalCost = additionalCostForPayload.find(
                            (additionalCost) =>
                                additionalCost.costName === element.field_name
                        );
                        OldFormulaString +=
                            newAdditionalCost?.costValue.toString();
                    } else if (foundAdditionalCost)
                        OldFormulaString +=
                            foundAdditionalCost.costValue.toString()
                                ? foundAdditionalCost.costValue
                                : '0';
                    else OldFormulaString += '0';
                }
            }
        }
        // if (is_log)
    });

    if (formulaString.trim() === '' || formulaString === undefined) {
        if (OldFormulaString.endsWith('+')) {
            OldFormulaString = OldFormulaString.slice(0, -1);
        }
        // eslint-disable-next-line no-eval
        calcValue = eval(OldFormulaString);
    }

    additionalCostForPayload.push({
        costName: additionalCost.costName,
        sequence: additionalCost.sequence,
        costSource: additionalCost.costSource,
        allocationType: additionalCost.allocationType,
        additional_cost_id: additionalCost.additional_cost_id,
        costType: additionalCost.costType,
        costValue:
            calcValue !== null &&
            calcValue !== undefined &&
            calcValue !== Infinity
                ? calcValue
                : costInformationInAdmin
                ? costInformationInAdmin?.cost_value
                : additionalCost.costValue,
        sourceValue: costInformationInAdmin
            ? costInformationInAdmin?.cost_value
            : additionalCost.sourceValue,
        additional_cost_linkage_id: additionalCost?.additional_cost_linkage_id,
        sourceCurrencyCodeAbbreviation:
            additionalCost.sourceCurrencyCodeAbbreviation,
        sourceCurrencySymbol: additionalCost.sourceCurrencySymbol,
        conversionRate: additionalCost.conversionRate,
        source_currency_id:
            calcValue && calcValue !== Infinity
                ? null
                : costInformationInAdmin
                ? costInformationInAdmin?.currency ?? null
                : additionalCost.source_currency_id ?? null,
        formula: costInformationInAdmin
            ? costInformationInAdmin?.formula?.formula
            : additionalCost.formula,
        is_calculated: additionalCost.is_calculated,
        selected: additionalCost.selected,
    });

    additionalCostFormulaCalculated[additionalCost.costName.toLowerCase()] = {
        calculated: true,
        costValue:
            calcValue !== null &&
            calcValue !== undefined &&
            calcValue !== Infinity
                ? (+calcValue as any)
                : costInformationInAdmin
                ? costInformationInAdmin.cost_value
                : additionalCost.costValue,
    };
    return {
        additionalCostForPayload: additionalCostForPayload,
        additionalCostFormulaCalculated: additionalCostFormulaCalculated,
    };
};

interface calculateFormulaProps {
    additionalCosts: calculateAdditionalCostInterface[];
    custom_fields: {
        name: string;
        value: string;
        type: CustomTemplateItemTypes;
    }[];
    quantity: number | null;
    targetRate: number | null;
    currencyAbbreviation?: string;
    reqAdditionalCosts?: calculateAdditionalCostInterface[];
    adminAdditionalCost?: IAdditionalCostInformation[];
    is_log?: boolean;
}

export const calculateAdditionalCostForFormula = (
    props: calculateFormulaProps
) => {
    let {
        additionalCosts,
        custom_fields,
        quantity,
        targetRate,
        currencyAbbreviation,
        reqAdditionalCosts,
        adminAdditionalCost,
        is_log,
    } = props;

    let additionalCostForPayload: calculateAdditionalCostInterface[] = [];

    let additionalCostFormulaCalculated: {
        [key: string]: {
            calculated: boolean;
            costValue?: number;
        };
    } = {};
    for (let cost of additionalCosts) {
        additionalCostFormulaCalculated[cost.costName.toLowerCase()] = {
            calculated: false,
            costValue: undefined,
        };
    }
    //
    for (let cost of additionalCosts) {
        try {
            if (cost.costSource !== CostSourceEnum.FORMULA) {
                additionalCostFormulaCalculated[cost.costName.toLowerCase()] = {
                    calculated: true,
                    costValue: +cost.costValue,
                };
                additionalCostForPayload.push(cost);
            }
        } catch (e) {
            console.error(
                'which cost am i in catch iterating over',
                e,
                cost.costName,
                additionalCostForPayload
            );
        }
    }
    for (let cost of additionalCosts) {
        try {
            if (cost.costSource === CostSourceEnum.FORMULA) {
                let temp = recursivelyCalculateAdditionalCostForFormula(
                    additionalCostFormulaCalculated,
                    cost,
                    additionalCosts,
                    additionalCostForPayload,
                    custom_fields,
                    currencyAbbreviation ?? '',
                    quantity,
                    targetRate,
                    adminAdditionalCost,
                    is_log
                );

                additionalCostForPayload = temp.additionalCostForPayload;
                additionalCostFormulaCalculated =
                    temp.additionalCostFormulaCalculated;
            }
        } catch (e) {
            console.error(
                'which cost am i in catch iterating over',
                e,
                cost.costName,
                additionalCostForPayload
            );
        }
    }

    if (reqAdditionalCosts) {
        return additionalCostForPayload.filter((cost1) =>
            reqAdditionalCosts?.some(
                (cost2) =>
                    cost1.costName.trim().toLowerCase() ===
                    cost2.costName.trim().toLowerCase()
            )
        );
    }

    // if (is_log)

    return additionalCostForPayload;
};

export const calculateAdditionalCostForFormulaCustomFields = ({
    item,
    listOfFormulaForCustomFields,
}: {
    item: ICostingCalculatorApiResponse;
    listOfFormulaForCustomFields: IGetFormulaResponse[] | null;
}) => {
    let formulaString = '';
    let updatedItemValueWithFormula = cloneDeep(item);
    let additionalCosts = convertAdditionalCostStructureForFormula(
        updatedItemValueWithFormula.additional_costs
    );
    let custom_fields = formatCustomFieldsForFormula(
        updatedItemValueWithFormula.custom_sections
    );

    let customFieldsList = updatedItemValueWithFormula.custom_sections
        .map((section) => {
            return section.custom_fields.map((field) => field.name);
        })
        .flat();

    let formualCustomFieldsList = listOfFormulaForCustomFields
        ?.filter((field) => customFieldsList?.includes(field.field_name))
        .map((field) => field.field_name);

    customFieldsList?.forEach((customField) => {
        const formulaField = listOfFormulaForCustomFields?.filter(
            (field) => field.field_name === customField
        );

        formulaString =
            formulaField?.[formulaField.length - 1]?.formula?.formula_string ??
            '';

        let calcValue: string | undefined | number = undefined;

        if (formulaString !== '') {
            try {
                const tokenNodes = getTokenNodes(formulaString);

                calcValue = evaluateTokenNodes(tokenNodes, (v: string) => {
                    const foundAdditionalCost = additionalCosts.find(
                        (cost) =>
                            cost.costName.toLowerCase().trim() ===
                            v.toLowerCase()
                    );

                    if (v.toLowerCase() === 'target rate') {
                        return item.rate?.toString() ?? '';
                    }
                    if (v.toLowerCase() === 'quantity') {
                        return item.quantity?.toString() ?? '';
                    }
                    if (foundAdditionalCost) {
                        return foundAdditionalCost.costValue.toString();
                    }

                    const foundCustomFields = custom_fields.find(
                        (cf) =>
                            cf.name.toLowerCase().trim() ===
                            v.toLowerCase().trim()
                    );

                    if (
                        foundCustomFields &&
                        formualCustomFieldsList?.includes(
                            foundCustomFields.name
                        )
                    ) {
                        return (
                            calculateAdditionalCostForFormulaCustomFieldsValueRecursively(
                                {
                                    item: updatedItemValueWithFormula,
                                    listOfFormulaForCustomFields,
                                    formualCustomFieldsList,
                                    customField: foundCustomFields.name,
                                }
                            ) ?? ''
                        );
                    } else if (foundCustomFields && foundCustomFields.value) {
                        if (isArray(foundCustomFields.value)) {
                            return `[${foundCustomFields.value.join(', ')}]`;
                        }

                        return foundCustomFields.value.toString();
                    }

                    return '';
                });

                let foundIndex =
                    updatedItemValueWithFormula.custom_sections[0]?.custom_fields?.findIndex(
                        (field) => {
                            return field.name === customField;
                        }
                    );

                if (foundIndex >= 0) {
                    updatedItemValueWithFormula.custom_sections[0].custom_fields[
                        foundIndex
                    ].value = !isNaN(+calcValue)
                        ? +calcValue < 0
                            ? '0'
                            : calcValue
                        : null;
                    updatedItemValueWithFormula.custom_sections[0].custom_fields[
                        foundIndex
                    ].is_formula = true;
                }
            } catch (error) {
                console.error(error);
            }
        }
    });
    return updatedItemValueWithFormula;
};

const calculateAdditionalCostForFormulaCustomFieldsValueRecursively = ({
    item,
    listOfFormulaForCustomFields,
    formualCustomFieldsList,
    customField,
}: {
    item: ICostingCalculatorApiResponse;
    listOfFormulaForCustomFields: IGetFormulaResponse[] | null;
    formualCustomFieldsList: string[];
    customField: string;
}) => {
    let formulaString = '';
    let updatedItemValueWithFormula = cloneDeep(item);
    let additionalCosts = convertAdditionalCostStructureForFormula(
        updatedItemValueWithFormula.additional_costs
    );
    let custom_fields = formatCustomFieldsForFormula(
        updatedItemValueWithFormula.custom_sections
    );

    const formulaField = listOfFormulaForCustomFields?.filter(
        (field) => field.field_name === customField
    );

    formulaString =
        formulaField?.[formulaField.length - 1]?.formula?.formula_string ?? '';

    let calcValue: string | undefined | number = undefined;

    if (formulaString !== '') {
        try {
            const tokenNodes = getTokenNodes(formulaString);

            calcValue = evaluateTokenNodes(tokenNodes, (v: string) => {
                const foundAdditionalCost = additionalCosts.find(
                    (cost) =>
                        cost.costName.toLowerCase().trim() === v.toLowerCase()
                );

                if (v.toLowerCase() === 'target rate') {
                    return item.rate?.toString() ?? '';
                }
                if (v.toLowerCase() === 'quantity') {
                    return item.quantity?.toString() ?? '';
                }
                if (foundAdditionalCost) {
                    return foundAdditionalCost.costValue.toString();
                }

                const foundCustomFields = custom_fields.find(
                    (cf) =>
                        cf.name.toLowerCase().trim() === v.toLowerCase().trim()
                );

                if (
                    foundCustomFields &&
                    formualCustomFieldsList.includes(foundCustomFields.name)
                ) {
                    return (
                        calculateAdditionalCostForFormulaCustomFieldsValueRecursively(
                            {
                                item: updatedItemValueWithFormula,
                                listOfFormulaForCustomFields,
                                formualCustomFieldsList,
                                customField: foundCustomFields.name,
                            }
                        ) ?? ''
                    );
                } else if (foundCustomFields && foundCustomFields.value) {
                    if (isArray(foundCustomFields.value)) {
                        return `[${foundCustomFields.value.join(', ')}]`;
                    }

                    return foundCustomFields.value.toString();
                }

                return '';
            });

            return calcValue;
        } catch (error) {
            console.error(error);
            return '';
        }
    }
};

export const getListOfReferencesname = (tokens: TokenNode[]) => {
    let output: string[] = [];

    for (let token of tokens) {
        if (token.type === TokenType.ReferenceName) {
            output = output.concat(token.value.toLowerCase());
        } else {
            output = output.concat(getListOfReferencesname(token.innerNodes));
        }
    }

    return output.filter((v) => v.toLowerCase().trim().length > 0);
};

import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import { IPaymentTerms } from '../../Global/Interfaces/PaymentTermsInterface';
import { IMeasurementUnitDetails } from '../../Models/MeasurementUnit.model';
import { IAttachment } from '../../Models/RFQ.model';
import { IMeasurement } from '../../Organizations/Admin/Interfaces/ItemDirectoryInterface';

export enum ContractStatusEnum {
    DRAFT = 'DRAFT',
    EXPIRED = 'EXPIRED',
    RENEWED = 'RENEWED',
    ONGOING = 'ONGOING',
    SUBMITTED = 'SUBMITTED',
    TERMINATED = 'TERMINATED',
}

export interface ICLMDashboardDetails {
    contract_uuid: string;
    contract_status: string;
    contract_name: string;
    status: ContractStatusEnum;
    contract_id: string;
    contract_item_count: number;
    contract_start_date: string;
    contract_end_date: string;
    buyer_entity: string;
    buyer_entity_name: string;
    seller_entity: string;
    buyer_identification: string[];
    seller_entity_name: string;
    template_id: string;
    item_details: {
        item_name: string;
        item_code: string;
    }[];
    project_information: {
        project_code: string;
        project_id: string;
        project_name: string;
    };
}

export interface ISKU {
    buyer_sku_id: string;
    sku_name: string;
    vendor_details: {
        vendor_name: string;
        vendor_sku_id: string[];
    }[];
    specification_details: {
        name: string;
        value: string[];
    }[];
}

export interface CreateContractCustomFields {}

export interface ICreateContractDetails {
    contract_status: string;
    contract_attachments: IAttachment[];
    contract_name: string;
    contract_start_date: string;
    contract_end_date: string;

    buyer_entity_name: string;
    buyer_identifications_id: string;
    buyer_identifications_name: string;
    buyer_contact_id: string;
    buyer_contact_name: string;
    buyer_address_id: string;
    buyer_address_name: string;
    buyer_entity_id: string;
    custom_fields: IContractCustomFieldSections;
    template_id: string;
    template_name: string;
    project_id: string;
    project_name: string;
    project_code: string;
    terms_and_conditions: {
        terms_and_conditions_id: string | null;
        name: string | null;
        data: string;
    };
}

export interface ICreateContract {
    contract_status: string;
    contract_attachments: IAttachment[];
    contract_uuid: string;
    contract_id: string;
    contract_name: string;
    contract_start_date: string;
    contract_end_date: string;
    buyer_identifications_id: string;
    buyer_identifications_name: string;
    buyer_identifications_value: string;
    buyer_contact_id: string;
    buyer_contact_name: string;
    buyer_address_id: string;
    buyer_address_name: string;
    buyer_entity_id: string;
    buyer_entity_name: string;
    vendor_attachments: IAttachment[];
    vendor_entity_id: string;
    vendor_entity_name: string;
    vendor_enterprise_id: string;
    vendor_identifications_value: string;
    vendor_identifications_name: string;
    vendor_contact_id: string;
    vendor_contact_name: string;
    vendor_address_id: string;
    vendor_address_name: string;
    custom_fields: IContractCustomFieldSections;
    item_details: IContractItemDetails[];
    general_terms: string;
    template_id: string;
    project_id: string;
    project_name: string;
    project_code: string;
    template_name: string;
    terms_and_conditions: {
        terms_and_conditions_id: string | null;
        name: string | null;
        data: string;
    };
}

export interface IContractItemDetails {
    isDisabled: boolean;
    isItemCreated: boolean;
    item_uuid: string;
    item_attachments: IAttachment[];
    item_name: string;
    item_id: string;
    item_description: string;
    item_code: string;
    item_attributes: {
        attribute_name: string;
        attribute_value: string[];
        attribute_exclude: boolean;
    }[];
    item_tags: string[];
    item_measurement_units: IMeasurement[];
    item_measurement_unit_id: string;
    item_measurement_unit_category: string;
    item_measurement_unit_value_type: string;
    item_measurement_unit_primary_name: string;
    item_currency_code: string;
    item_pricing_value: string;
    item_quantity: string;
    lead_time: string;
    lead_time_period: string;
    item_custom_fields: IContractItemsCustomFieldSections;
    item_buyer_skus: string[];
    item_paymentTerms: IPaymentTerms;
}

export interface IContractItemDetailsNew {
    isDisabled: boolean;
    isItemCreated: boolean;
    item_uuid: string;
    item_attachments: IAttachment[];
    item_name: string;
    item_id: string;
    item_description: string;
    item_code: string;
    item_attributes: {
        attribute_name: string;
        attribute_value: string[];
        attribute_exclude: boolean;
    }[];
    additional_costs: IAdditionalCostsBackend[];
    taxes: IAdditionalCostsBackend[];
    discounts: IAdditionalCostsBackend[];
    item_tags: string[];
    item_measurement_units: IMeasurementUnitDetails[];
    item_measurement_unit_id: string;
    item_measurement_unit_category: string;
    item_measurement_unit_value_type: string;
    item_measurement_unit_primary_name: string;
    item_currency_code: string;
    item_currency_abbreivation: string;
    item_currency_symbol: string;
    item_pricing_value: string;
    item_quantity: string;
    incoterm_id: string;
    lead_time: string;
    lead_time_period: string;
    item_custom_fields: IContractItemsCustomFieldSections;
    item_buyer_skus: string[];
    item_paymentTerms: IPaymentTerms;
}

export interface IBuyerIdentifications {
    //TODO: Remove all the 'any' types. Thats very bad practice for typescript.
    identification_id: string;
    attachments: IBuyerIdentificationsAttachments[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string;
    identification_name: string;
    identification_category: string;
    identification_value: string;
    is_default: boolean;
    is_public: boolean;
    status: string;
    verification_status: string;
    notes: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string;
    entity: string;
    verification: string;
}

export interface IBuyerIdentificationsAttachments {
    attachment_id: string;
    attachment_type: string;
    storage_id: string;
    resource_id: string;
    file_name: string;
    attachment_status: string;
}

export interface IVendorContactsCLM {
    vendor_contact_id: string;
    buyer_entity: string;
    seller_entity: string;
    full_name: string;
    primary_email: string;
    emails: string[];
    phone_numbers: string[];
    tags: string[];
    is_primary: boolean;
    user: string;
    status: string;
}

export interface CLMPageEntityDetails {
    entity_id: string;
    default_procurement_currency?: {
        entry_id: string;
        currency_code_abbreviation: string;
        currency_name: string;
        currency_symbol: string;
    };
    vendor_profile?: {
        vendor_profile_id: string;
    };
    entity_logo: Array<{
        attachment_id: string;
        attachment_type: string;
        storage_id: string;
        resource_id: string;
        file_name: string;
        attachment_status: string;
    }>;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string;
    entity_name: string;
    entity_description?: string;
    entity_doing_business_as: string;
    buyer_status: string;
    seller_status: string;
    verification_status: string;
    setup_information: {
        buyer: {
            item: boolean;
            vendor: boolean;
            identification: boolean;
            billing_address: boolean;
            shipping_address: boolean;
            terms_and_conditions: boolean;
        };
        seller: {
            identification: boolean;
            primary_address: boolean;
        };
    };
    is_virtual: boolean;
    is_user_email_verified: boolean;
    contacts: {
        emails: Array<string>;
        websites: Array<string>;
        phone_numbers: Array<string>;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    enterprise: string;
}

export interface ICLMCustomField {
    name: string;
    description: string;
    value: string | boolean | string[] | null;
    type: string;
    is_required: boolean;
    is_visible: boolean;
    is_locked: boolean;
}

export interface ICLMCustomFieldsBackend {
    section_list: {
        name: string;
        fields: ICLMCustomField[];
    }[];
}

// export interface IContractCustomFieldSections {
//     existingSections: {
//         contract_details: ICLMCustomField[];
//         vendor_details: ICLMCustomField[];
//     };
// }

export interface IContractCustomFieldSections {
    section_list: {
        name: string;
        fields: ICLMCustomField[];
    }[];
}

export interface IContractItemsCustomFieldSections {
    section_list: {
        name: string;
        fields: ICLMCustomField[];
    }[];
}

export interface IItemBuyerSKUsTable {
    id: string;
    buyer_sku_uuid: string;
    buyer_sku_id: string;
    buyer_sku_name: string;
    vendor_sku_ids: string[];
    vendor_sku_names: string[];
}

export interface IContractProject {
    project_id: string;
    project_name: string;
    project_code: string;
    status: 'INACTIVE' | 'ACTIVE';
    buyer_entity: string;
}

export interface IListOfContracts {
    contract_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    base_contract_id: string;
    custom_contract_id: string;
    contract_name: string;
    contract_start_date: string;
    contract_end_date: string;
    buyer_identifications: Array<any>;
    buyer_contact_information: {
        buyer_contact_id: string;
        buyer_contact_name: string;
        buyer_contact_email: string;
    };
    buyer_address_information: {
        address_id: string;
        full_address: string;
    };
    vendor_identifications: any;
    vendor_contact_information: any;
    status: string;
    additional_details: {
        template_id: string;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    buyer_enterprise: string;
    buyer_entity: string;
    seller_enterprise: any;
    seller_entity: any;
}

export interface ArgumentsForPaginationApiCallContracts {
    dashboard_view: 'contract_items';
    search_text: string;
    tab: 'all';
    sort_fields: any[];
    items_per_page: 10;
    page_number: number;
    query_data: {
        contract_id: string;
    };
}

import { FormulaInterface } from '../../AdditionalCost/Interfaces/AdditionalCostInterfaces';
import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
} from '../../AdditionalCost/models/AdditionalCost.model';
import { IUserPermissionFEStructure } from '../../BuyerTemplates/Component/ShareSectionPopup';
import { IEventItemCustomFieldsBackend } from '../../Events/Interfaces/Buyer/RFQ.model';
import { IItemCartLevelCustomField } from '../../ItemAnalytics/Interfaces/itemCartItemInterfaces';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { CustomFieldType } from '../../SRM/Constants/Interfaces/documentTemplate';
import { IAdditionalCostStructureForQuoteWithCurrency } from '../Services/CostingApisCalls.service';

// interface ICostingItemData {
//     // itemId: string;
//     selectedItem: IsellerItemList | null;
//     quantity: number;
//     selectedUnit: {
//         measurement_unit_id: string;
//         measurement_unit_primary_name: string;
//     } | null;
//     rate: number;
//     additionalItemCharges: AdditionalCostDataInterface[];
// }

export interface AdditionalItemCostingDataInterface
    extends AdditionalFinalCostingDataInterface {
    costAllocation: 'OVERALL_QUANTITY' | 'PER_UNIT';
}

export interface AdditionalFinalCostingDataInterface {
    id?: number;
    cost_name: string;
    // sequence required
    sequence: number;
    cost_type: CostTypeEnum;
    cost_currency_id?: string | null;
    cost_value: string | number;
    allocation_type: AllocationTypeEnum | null;
    cost_source: CostSourceEnum | null;
    additional_cost_id: string | null;
    additional_cost_linkage_id: string | null;
    is_calculated: boolean;
    source_value?: number | null;
    source_currency_currency_code_abbreviation?: string | null;
    source_currency_currency_symbol?: string | null;
    source_currency_id?: string | null;
    conversion_rate?: number | null;
    formula?: {
        operands: FormulaInterface[];
        formula_string?: string | null;
    } | null;
}

export interface AdditionalFinalCostingDataWithCurrencyInterface {
    // id: number;
    costName: string;
    costType: CostTypeEnum;
    sequence: number;
    value: string;
    cost_source: CostSourceEnum | null;
    currency_id: string | null;
    source_currency_currency_code_abbreviation?: string | null;
    source_currency_currency_code_symbol?: string | null;
    source_currency_id?: string | null;
    source_value?: number | null;
    cost_currency_id: string | null;
    autofill?: boolean;
    allocation_type: AllocationTypeEnum | null;
    additional_cost_id: string | null;
    is_calculated: boolean;
    formula?: {
        formula_string: string;
        operands: FormulaInterface[] | null;
    } | null;
}

export interface ICosting {
    entity: string; // this entity uuid
    currency: ICurrencyDetails;
    costingName: string;
    version: number;
    status: CostingSheetStatusEnum;
    customer_entity_id: string | null;
    customer_entity_name: string | null;
    customer_contact_ids: string[];
    view: 'SIMPLE' | 'ADVANCED';
    validity_datetime: string | null;
    deadline_datetime: string | null;
    additionalCosts: AdditionalFinalCostingDataInterface[];
    total: number;
    costingData: (ICostingItem | ICostingBOM)[];
    additional_details: {
        template_id: string;
    };
    custom_fields: {
        section_list: { name: string; fields: IItemCartLevelCustomField[] }[];
    };
}

export enum CostingSheetStatusEnum {
    DRAFT = 'DRAFT', //hi
    APPROVAL_PENDING = 'APPROVAL_PENDING',
    PAUSE_AND_EDIT = 'PAUSE_AND_EDIT', //hi
    REWORK = 'REWORK', //hi
    REJECTED = 'REJECTED',
    SUBMITTED = 'SUBMITTED',
    CHANGES_REQUESTED = 'CHANGES_REQUESTED',
    CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
    CUSTOMER_ACCEPTED = 'CUSTOMER_ACCEPTED',
}

export interface IBuyerContactResponse {
    buyer_contact_id: string;
    user: {
        user_id: string;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: null;
        user_picture_url: string;
        email: string;
        username: null;
        name: string;
        first_name: null;
        last_name: null;
        role: string;
        status: string;
        description: null;
        designation: null;
        defaults: null;
        contacts: null;
        additional_details: null;
        onboarding_information: {
            checklist: boolean;
            onboarding: boolean;
            welcome_pop_up: boolean;
        };
        is_email_verified: boolean;
        is_virtual: boolean;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: null;
        enterprise: string;
    } | null;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: null;
    full_name: string;
    primary_email: string;
    emails: string[];
    phone_numbers: string[];
    tags: string[];
    is_primary: true;
    status: string;
    notes: null;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: null;
    enterprise_buyer_master: string;
    entity_buyer_master: string;
    seller_enterprise: string;
    seller_entity: string;
    buyer_enterprise: string;
    buyer_entity: string;
}

export interface IBaseCostingData {
    uuid: string | null;
    projects: {
        project_id: string;
        project_name: string;
        project_code: string;
    }[];
    rate: string;
    vendor_rate: number;
    lead_time: string | null;
    lead_time_period: string | null;
    quantity: string;
    notes: string | null;
    additional_costs: AdditionalFinalCostingDataInterface[];
    unit: {
        abbreviation: string;
        measurement_unit_category: string;
        measurement_unit_id: string;
        measurement_unit_primary_name: string;
        measurement_unit_value_type: string;
    } | null;
    vendor: {
        entity_id: string;
        entity_name: string;
    } | null;
    vendor_currency: {
        currency_code_id: string;
        currency_name: string;
        currency_code_abbreviation: string;
        currency_symbol: string;
        currency_decimal: number;
    } | null;
    rfqItemId: string | null;
    rfq_bid_item: {
        rfq_bid_item_id: string;
        pricing_information: {
            price: number;
            currency_name: string;
            currency_symbol: string;
            currency_code_id: string;
            shipping_per_unit: number;
            additional_charges: any[];
            currency_code_abbreviation: string;
        };
        additional_costs: AdditionalFinalCostingDataInterface[];
    } | null;

    itemId: string | null;
    rfq_event_details: {
        event_id: string;
        custom_event_id: string;
        event_name: string;
        rfq_entry_id: string;
    } | null;
    latestPO: {
        price: string;
        measurement_unit_id: string;
        measurement_unit_primary_name: string;
    } | null;
    custom_fields: IEventItemCustomFieldsBackend;
    custom_fields_negotiate: IEventItemCustomFieldsBackend;
    conversion_rate: number;
    enterprise_item_details: {
        name: string;
        code: string;
        custom_ids: {
            custom_ids: {
                name: string;
                value: string;
            }[];
        };
        attributes: {
            attributes: {
                attribute_name: string;
                attribute_value: string[];
                attribute_exclude: boolean;
            }[];
        };
    };
}

export interface ICostingItem extends IBaseCostingData {
    costType: 'ITEM';
    vendor_entity: string;
}

export interface ICostingBOM extends IBaseCostingData {
    costType: 'BOM';
    // bomName: string;
    bomId: string;
    // bomItemId: string;
    bomDetails: (ICostingItem | ICostingBOM)[];
    baseQuantity: string;
    vendor_entity: string;
}

export interface ICostingDashboard {
    costing_sheet_id: string;
    total: number;
    currency_details: {
        currency_code_abbreviation: string;
        currency_name: string;
        currency_symbol: string;
        entry_id: string;
    };
    customer_entity_details: {
        entity_id: string;
        entity_name: string;
    };
    customer_contact_details: {
        buyer_contact_id: string;
        full_name: string;
        phone_numbers: string[];
        primary_email: string;
    }[];
    costing_sheet_permissions: {
        user_id: string;
        name: string;
        permission: string;
    }[];
    created_datetime: string;
    modified_datetime: string;
    modified_by_user_name: string;
    created_by_user_details: { user_id: string; name: string };
    custom_costing_sheet_id: string;
    name: string;
    additional_costs: {
        cost_name: string;
        cost_type: string;
        cost_value: number;
    }[];
    view: 'SIMPLE' | 'ADVANCED';
    seller_enterprise: string;
    seller_entity_details: {
        entity_name: string;
        entity_id: string;
    };
    currency_code: string;
    item_details: {
        enterprise_item_details: {
            name: string;
        };
        enterprise_bom__bom_code: string | null;
    }[];
    additional_details: {
        template_id: string;
    };
}

export interface BOMDetailsItemType {
    vendor_rate: number;
    bom_item_id: string | null;
    procurement_information: {
        lead_time: string | null;
        lead_time_period: string | null;
    };
    projects: {
        project_id: string;
        project_name: string;
        project_code: string;
    }[];
    // enterprise_sub_bom: IBOMDetailsResponse | null;
    vendor_entity: string | null;
    vendor_currency: string | null;
    created_datetime: string | null;
    modified_datetime: string | null;
    deleted_datetime: string | null;
    description: null;
    quantity: string;
    is_coproduct: boolean;
    is_bulk_material: boolean;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    raw_material_item: {
        enterprise_item_id: string;
    };
    measurement_unit: string;
    enterprise_bom: string;
    // sub_bom: string | null;
    sub_bom: IBOMDetailsResponse | null;
    enterprise_item_details: {
        name: string;
        code: string;
        custom_ids: {
            custom_ids: {
                name: string;
                value: string;
            }[];
        };
        attributes: {
            attributes: {
                attribute_name: string;
                attribute_value: string[];
                attribute_exclude: boolean;
            }[];
        };
    };
}

export interface IBOMDetailsResponse {
    enterprise_item_details: {
        name: string;
        code: string;
        custom_ids: {
            custom_ids: {
                name: string;
                value: string;
            }[];
        };
        attributes: {
            attributes: {
                attribute_name: string;
                attribute_value: string[];
                attribute_exclude: boolean;
            }[];
        };
    };
    enterprise_bom_id: string;
    vendor_entity: string | null;
    vendor_currency: string | null;
    created_datetime: string | null;
    modified_datetime: string | null;
    deleted_datetime: string | null;
    bom_code: string;
    // bom_name: string;
    projects: {
        project_id: string;
        project_name: string;
        project_code: string;
    }[];
    quantity: string;
    locations: string[];
    is_primary: boolean;
    status: 'ACTIVE' | 'INACTIVE';
    notes: string | null;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    seller_enterprise: string;
    enterprise_item: {
        enterprise_item_id: string;
    };
    vendor_rate: number;
    measurement_unit: string;
    currency: string;
    bom_items: BOMDetailsItemType[];
}

export interface IBOMCostingBackendStructure {
    vendor_rate: number;
    costing_sheet_item_id: string | null;
    rfq_event_item: string | null;
    rfq_bid_item: string | null;
    enterprise_item: string | null;
    projects: string[];
    lead_time: string | null;
    lead_time_period: string | null;
    enterprise_bom: string | null;
    costing_sheet_item_boms: IBOMCostingBackendStructure[];
    quantity: string;
    rate: string;
    notes: string | null;
    additional_costs: IAdditionalCostStructureForQuoteWithCurrency[];
    measurement_unit_details: {
        measurement_unit_id: string;
        measurement_unit_primary_name: string;
    } | null;
    measurement_unit: string | null;
    latest_po_item: {
        price: string;
        measurement_unit_id: string;
        measurement_unit_primary_name: string;
    } | null;
    enterprise_bom_details: {
        base_quantity: number;
    } | null;
    custom_fields: IEventItemCustomFieldsBackend;
    custom_fields_negotiate: IEventItemCustomFieldsBackend;
    vendor_entity: string | null;
    vendor_entity_details: {
        entity_id: string;
        entity_name: string;
    } | null;
    vendor_currency: string | null;
    conversion_rate: number;
    vendor_currency_details: {
        currency_code_id: string;
        currency_name: string;
        currency_code_abbreviation: string;
        currency_symbol: string;
    } | null;
    enterprise_item_details: {
        name: string;
        code: string;
        attributes: {
            attributes: {
                attribute_name: string;
                attribute_value: string[];
                attribute_exclude: boolean;
            }[];
        };
    };
}

export interface ICreateCostingSheetPayload {
    seller_entity_id: string;
    currency_code_id: string | null; // that entities default currency
    name: string; // use default name
    customer_entity_id: string | null; // null
    customer_contact_ids: string[]; // []
    additional_costs: AdditionalFinalCostingDataInterface[]; // []
    deadline_datetime: string | null; // null
    validity_datetime: string | null; // null
    template_id: string;
    admin_conversions_selected: boolean;
    custom_currency_conversions: {
        from_currency_id: string;
        to_currency_id: string;
        conversion_rate: string;
    }[];
    custom_sections: {
        // custom_section_id: string | null;
        start_time: string | null;
        status: 'ASSIGNING_USERS' | 'DRAFT' | 'SUBMITTED' | 'REVISED';
        view_users: string[];
        edit_users: string[];
        assigned_users: string[];
        name: string;
        section_type: 'OTHER' | 'BOM' | 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[];
    custom_fields: {
        section_list: [];
    };
    custom_fields_negotiate: {
        section_list: [];
    };
}

export interface ICostingLevelDataErrors {
    quote_name?: string;
    quote_currency?: string;
    validity_datetime?: string;
    deadline_datetime?: string;
    customer_name?: string;
    custom_fields: {
        [key: string]: string;
    };
}

export interface ICostingErrors {
    qoute_level: {
        quote_name?: string;
        quote_currency?: string;
        validity_datetime?: string;
        deadline_datetime?: string;
        customer_name?: string;
        custom_fields?: {
            [field: string]: string;
        };
    };
    item_level: {
        [itemNumber: string]: {
            quantity?: string;
            rate?: string;
            lead_time?: string;
            notes?: string;
            additionalCost?: string;
            custom_fields?: {
                [field: string]: string;
            };
        };
    };
}

export interface IQUoteItemDeliverySchedulePayload {
    delivery_schedule_item_id: string | null;
    delivery_date: string | null;
    quantity: number;
    project_id: string | null;
    cost_centre_id: string | null;
    general_ledger_id: string | null;
    customer_entity_id: string | null;
    bom_item_id: string | null;
    requisition_item_id: string | null;
    parent_delivery_schedule_item_id: string | null;
}

export interface IQUoteItemDeliveryScheduleBomItem {
    enterprise_bom: {
        entry_id: string;
        bom_code: string;
    } | null;
    parent_bom_item_module_linkage: string | null;
    entry_id: string;
    index: number;
    parent_sub_bom_item: IQUoteItemDeliveryScheduleBomItem | null;
    sub_bom: {
        bom_code: string;
    } | null;
}

export interface IQUoteItemDeliverySchedule {
    bom_item: IQUoteItemDeliveryScheduleBomItem | null;
    customer_entity: string | null;
    delivery_date: string | null;
    cost_centre_id: string | null;
    general_ledger_id: string | null;
    project_id: string | null;
    quantity: number;
    delivery_schedule_item_id: string | null;
    project_code: string | null;
    cost_centre_name: string | null;
    general_ledger_code: string | null;
}

export interface IQuoteCustomField {
    // custom_field_id: string | null;
    name: string;
    type: CustomFieldType;
    value: string | boolean | string[] | null;
    is_locked: boolean;
    is_visible: boolean;
    description: string;
    is_required: boolean;
    is_negotiable: boolean;
    is_formula?: boolean;
}

export interface ICostingDetailsBackendResponse {
    costing_sheet_id: string;
    total: string;
    currency_details: {
        currency_code_abbreviation: string;
        currency_name: string;
        currency_symbol: string;
        entry_id: string;
        decimals: number;
    };
    modified_by_user_name: string;
    item_details: {
        enterprise_item__name: string;
        enterprise_bom__bom_code: string | null;
    }[];
    customer_contacts: string[];
    additional_costs: {
        conversion_rate: number | null;
        additional_cost_id: string | null;
        sequence: number;
        additional_cost_linkage_id: string | null;
        cost_name: string;
        cost_type: CostTypeEnum;
        is_calculated: boolean;
        allocation_type: AllocationTypeEnum;
        cost_value: number;
        source_currency_id: string | null;
        source_currency_currency_code_abbreviation: string | null;
        source_currency_currency_symbol: string | null;
        source_value?: number | null;
        cost_source: CostSourceEnum | null;
    }[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    custom_costing_sheet_id: string;
    name: string;
    version: number;
    status: CostingSheetStatusEnum;
    deadline_datetime: string;
    validity_datetime: string;
    view: 'ADVANCED' | 'SIMPLE';
    additional_details: {
        template_id: string;
    };
    custom_fields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_sections: {
        // custom_section_id: string;
        start_time: string | null;
        status: 'ASSIGNING_USERS' | 'DRAFT' | 'SUBMITTED' | 'REVISED';
        view_users: string[];
        edit_users: string[];
        assigned_users: string[];
        name: string;
        section_type: 'OTHER' | 'BOM' | 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[];
    created_by_user_details: { user_id: string; name: string };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    seller_enterprise: string;
    seller_entity_details: {
        entity_id: string;
        entity_name: string;
    };
    customer_entity_details: {
        entity_id: string;
        entity_name: string;
    };
    currency_code: string;
}

export interface IQuoteDetails {
    costing_sheet_id: string;
    custom_costing_sheet_id: string;
    total: number;
    currency_details: {
        currency_code_abbreviation: string;
        currency_name: string;
        currency_symbol: string;
        entry_id: string;
        decimals: number;
    };
    seller_entity: string;
    seller_entity_name: string;
    costing_sheet_name: string;
    customer_entity_details: {
        entity_name: string;
        entity_id: string;
    };
    customer_contact_ids: string[];
    version: number;
    status: CostingSheetStatusEnum;
    deadline_datetime: string | null;
    validity_datetime: string | null;
    custom_fields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_sections: {
        // custom_section_id: string | null;
        start_time: string | null;
        status: 'ASSIGNING_USERS' | 'DRAFT' | 'SUBMITTED' | 'REVISED';
        view_users: string[];
        edit_users: string[];
        assigned_users: string[];
        name: string;
        section_type: 'OTHER' | 'BOM' | 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[];
    additional_costs: AdditionalFinalCostingDataInterface[];
    additional_details: {
        template_id: string;
    };
    view: 'SIMPLE' | 'ADVANCED';
    created_datetime: string;
    created_by_user_details: { user_id: string; name: string };
    quote_managers: { user_id: string; name: string; permission: string }[];
}

export interface IQuoteAssignUserAndPM {
    QUOTE_DETAILS: IUserPermissionFEStructure[];
    ESSENTIAL_TERMS: IUserPermissionFEStructure[];
    OVERALL_ADDITIONAL_COSTS: IUserPermissionFEStructure[];
    BOM: IUserPermissionFEStructure[];
    [sectionName: string]: IUserPermissionFEStructure[];
}

export interface IQuoteAssignUserListResponse {
    user_id: string;
    name: string;
    first_name: string | null;
    last_name: string | null;
    entity_id: string;
    entity_name: string;
    action_api_group: string;
    criteria: any;
}

export interface IQuoteUpdatePayload {
    seller_entity_id: string;
    currency_code_id: string;
    customer_contact_ids: string[];
    validity_datetime: string | null;
    deadline_datetime: string | null;
    custom_fields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_sections: {
        name: string;
        section_type: 'OTHER' | 'BOM' | 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[];
    name: string;
    customer_entity_id: string;
    additional_costs: {
        cost_name: string;
        cost_type: CostTypeEnum;
        additional_cost_id: string | null;
        allocation_type: AllocationTypeEnum | null;
        cost_value: string | number;
        source_currency_id: string | null;
        source_value: number | null;
    }[];
    view: 'SIMPLE' | 'ADVANCED';
    total: number;
    template_id: string;
}

export interface IQuoteItemUpdatePayload {
    rfq_event_item_id: string | null;
    rfq_bid_item_id: string | null;
    enterprise_item_id: string | null;
    enterprise_bom_id: string | null;
    projects: string[];
    lead_time: number | null;
    lead_time_period: string | null;
    quantity: number;
    rate: number;
    notes: string | null;
    measurement_unit_id: string | null;
    additional_costs: AdditionalFinalCostingDataInterface[];
    vendor_rate: number;
    costing_sheet_item_id: string | null;
    delivery_schedule: IQUoteItemDeliverySchedulePayload[];
    custom_fields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_sections: {
        // custom_section_id: string | null;
        start_time: string | null;
        last_modified_time: string | null;
        assigned_user_ids: string[];
        edit_user_ids: string[];
        view_user_ids: string[];
        name: string;
        section_type: 'ITEM';
        custom_fields: IQuoteCustomField[];
    }[];
    vendor_entity_id: string | null;
    vendor_currency_id: string | null;
    conversion_rate: number;
}

export interface ICostingHistory {
    history_id: number;
    modified_datetime: string;
    modified_by_user_id: string;
    modified_by_user_name: string;
    changes: {
        field: keyof ICostingDetailsBackendResponse;
        old_value: string;
        new_value: string;
    }[];
    costing_sheet_items: {
        costing_sheet_item_id: string;
        changes: {
            field: keyof ICostingItem;
            old_value: string;
            new_value: string;
        }[];
    }[];
}

export interface ICostingHistoryItem {
    history_id: number;
    measurement_unit_details: {
        abbreviation: string;
        measurement_unit_category: string;
        measurement_unit_id: string;
        measurement_unit_primary_name: string;
        measurement_unit_value_type: string;
    };
    rfq_event_details: {
        event_id: string;
        custom_event_id: string;
        event_name: string;
        rfq_event_item_id: string;
        rfq_entry_id: string;
    } | null;
    enterprise_bom_details: null;
    name: string;
    enterprise_item_details: {
        enterprise_item_id: string;
        name: string;
        code: string;
        custom_ids: {
            custom_ids: {
                name: string;
                value: string;
            }[];
        };
        measurement_units: {
            item_measurement_units: {
                abbreviation: string;
                measurement_unit_id: string;
                measurement_unit_category: string;
                measurement_unit_value_type: string;
                measurement_unit_primary_name: string;
            }[];
        };
        attributes: {
            attributes: {
                attribute_name: string;
                attribute_value: string[];
                attribute_exclude: boolean;
            }[];
        };
    };
    rfq_bid_item_details: {
        rfq_bid_item_id: string;
        pricing_information: {
            price: number;
            currency_name: string;
            currency_symbol: string;
            currency_code_id: string;
            shipping_per_unit: number;
            additional_charges: any[];
            currency_code_abbreviation: string;
        };
        additional_costs: AdditionalFinalCostingDataInterface[];
    } | null;
    additional_costs: AdditionalFinalCostingDataInterface[];
    created_datetime: string;
    deleted_datetime: string | null;
    costing_sheet_item_id: string;
    projects: {
        project_code: string;
        project_id: string;
        project_name: string;
    }[];
    vendor_entity_details: {
        entity_id: string;
        entity_name: string;
    } | null;
    vendor_currency_details: {
        entry_id: string;
        decimals: number;
        currency_name: string;
        currency_code_abbreviation: string;
        currency_symbol: string;
    } | null;
    quantity: number;
    rate: number;
    conversion_rate: number;
    procurement_information: {
        lead_time: string;
        lead_time_period: 'MONTHS' | 'WEEKS' | 'DAYS';
    };
    notes: string | null;
    custom_fields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    vendor_rate: number;
    history_date: string;
    history_change_reason: null;
    history_type: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: null;
    measurement_unit: null;
    costing_sheet: string;
    rfq_event_item: null;
    rfq_bid_item: {
        rfq_bid_item_id: string;
        pricing_information: {
            price: number;
            currency_name: string;
            currency_symbol: string;
            currency_code_id: string;
            shipping_per_unit: number;
            additional_charges: any[];
            currency_code_abbreviation: string;
        };
        additional_costs: AdditionalFinalCostingDataInterface[];
    } | null;
    enterprise_item: string;
    enterprise_bom: null;
    parent_costing_sheet_item: null;
    vendor_entity: string;
    vendor_currency: string;
    history_user: null;
}

export interface ICostingHistoryById {
    history_id: number;
    currency_details: {
        currency_code_abbreviation: string;
        currency_name: string;
        currency_symbol: string;
        entry_id: string;
        decimals: number;
    };
    seller_entity_details: {
        entity_name: string;
    };
    customer_entity_name: string | null;
    customer_contacts:
        | {
              buyer_contact_id: string;
              full_name: string;
              primary_email: string;
              phone_numbers: string[];
          }[]
        | null;
    additional_costs: AdditionalFinalCostingDataInterface[];
    created_datetime: string;
    deleted_datetime: string | null;
    costing_sheet_id: string;
    custom_costing_sheet_id: string;
    name: string;
    version: number;
    status: CostingSheetStatusEnum;
    deadline_datetime: null;
    validity_datetime: null;
    view: 'SIMPLE' | 'ADVANCED';
    total: number;
    additional_details: {
        template_id: string;
    };
    custom_fields: {
        section_list: { name: string; fields: IQuoteCustomField[] }[];
    };
    custom_fields_negotiate: {
        section_list: { name: string; fields: IQuoteCustomField[] }[];
    };
    history_date: string;
    history_change_reason: null;
    history_type: string;
    created_by_user_details: { user_id: string; name: string };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: null;
    seller_enterprise: string;
    seller_entity: string;
    customer_entity: string;
    currency_code: string;
    history_user: null;
    costing_sheet_items?: ICostingHistoryItem[];
}

export type quoteLevelDataPayload =
    | {
          key: 'total';
          value: number;
      }
    | {
          key: 'currency_details';
          value: {
              currency_code_abbreviation: string;
              currency_name: string;
              currency_symbol: string;
              entry_id: string;
              decimals: number;
          };
      }
    | {
          key: 'costing_sheet_name';
          value: string;
      }
    | {
          key: 'customer_entity_details';
          value: {
              id: string;
              name: string;
          };
      }
    | {
          key: 'customer_contact_ids';
          value: string[];
      }
    | {
          key: 'version';
          value: number;
      }
    | {
          key: 'status';
          value: CostingSheetStatusEnum;
      }
    | {
          key: 'deadline_datetime';
          value: string;
      }
    | {
          key: 'validity_datetime';
          value: string;
      }
    | {
          key: 'custom_fields';
          value: {
              //   custom_section_id: string | null;
              start_time: string | null;
              status: 'ASSIGNING_USERS' | 'DRAFT' | 'SUBMITTED' | 'REVISED';
              view_users: string[];
              edit_users: string[];
              assigned_users: string[];
              name: string;
              section_type: 'OTHER' | 'BOM' | 'ITEM';
              custom_fields: IQuoteCustomField[];
          }[];
      }
    | {
          key: 'additional_costs';
          value: AdditionalFinalCostingDataInterface[];
      };

export type QuoteItemAdditionalCostUpdatePayload =
    | {
          key: 'ADD_NEW_ADDITIONAL_COST';
          value: AdditionalFinalCostingDataInterface;
      }
    | {
          key: 'UPDATE_ADDITIONAL_COST';
          value: {
              index: number;
              value: AdditionalFinalCostingDataInterface;
          };
      }
    | {
          key: 'REMOVE_ADDITIONAL_COST';
          value: number;
      };
